import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from './popup/popup.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { MatNativeDateModule } from '@angular/material/core';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DatePipe } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';
import { MyordersPageComponent } from './myorders-page/myorders-page.component';
import { HomepageComponent } from './homepage/homepage.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { PlyrModule } from 'ngx-plyr';
import { VideoPageComponent } from './video-page/video-page.component';
import { TruckPageComponent } from './truck-page/truck-page.component';
@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    MyordersPageComponent,
    HomepageComponent,
    HeaderComponent,
    FooterComponent,
    VideoPageComponent,
    TruckPageComponent,
  ],
  entryComponents: [PopupComponent],
  imports: [
    FlexModule,
    GridModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    DatePickerModule,
    HttpClientModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    CdkAccordionModule,
    PlyrModule,
  ],

  providers: [
    DatePipe,
    AppComponent,
    PopupComponent,
    HomepageComponent,
    {
      provide: MatDialogRef,
      useValue: [],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
