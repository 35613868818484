import { Component, OnInit } from '@angular/core';
import { positionElements } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { LogicService } from '../service/logic.service';
import { TokenService } from '../service/token.service';

@Component({
  selector: 'app-myorders-page',
  templateUrl: './myorders-page.component.html',
  styleUrls: ['./myorders-page.component.scss'],
})
export class MyordersPageComponent implements OnInit {
  constructor(private ls: LogicService, private token: TokenService) {}
  address_available;
  pickup_address;
  drop_address;
  addresslist;
  documentlist: any = [];
  typeimage;
  bill_flag = false;
  invoice_flag = false;
  doc_flag = false;
  index = 0;
  bill: any = [];
  invoice: any = [];
  file: any = '';
  volume_unit = 'cm';
  loaded = false;
  user_id;
  months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  shipment_details: any = [];
  actualcost;
  actualadvance;
  view_more = false;
  empty_flag = false;
  table_flag = false;
  myorders: any = [];
  expandedIndex = 0;

  ngOnInit(): void {
    let token = this.token.getUser();
    if (token != null) {
      this.user_id = token.id;
      this.get_order_list();
      // console.log('user', token)
    }
    if (this.shipment_details.length > 0) {
      this.table_flag = true;
    }
    setTimeout(() => {
      window.scroll(0, 0);
    }, 500);
  }
  get_order_list() {
    this.ls
      .get_orders({ user_id: this.user_id })
      .then((res) => {
        console.log('response', res);
        if (res.status == 1) {
          this.myorders = res.order_list;
          if (this.myorders.length > 0) {
            for (let i in this.myorders) {
              // console.log(this.)
              if (this.myorders[i].pickup_address == null) {
                this.myorders[i].from_place =
                  this.myorders[i].from_location.split(',')[0];
              } else {
                this.myorders[i].from_place =
                  this.myorders[i].pickup_address.city;
              }

              if (this.myorders[i].drop_address == null) {
                this.myorders[i].to_place =
                  this.myorders[i].to_location.split(',')[0];
              } else {
                this.myorders[i].to_place = this.myorders[i].drop_address.city;
              }
              let date = this.myorders[i].booking_date.split('-');
              let format_date =
                date[0] +
                '-' +
                this.months[parseInt(date[1]) - 1] +
                '-' +
                date[2];
              this.myorders[i].date = format_date;
              let created = new Date(this.myorders[i].createdAt);
              let cd =
                created.getDate() < 10
                  ? '0' + created.getDate()
                  : created.getDate();
              (this.myorders[i].create_date =
                cd +
                '-' +
                this.months[created.getMonth()] +
                '-' +
                created.getFullYear()),
                console.log(this.myorders[i].create_date);

              //  else {
              //   this.myorders[i].shipment_details[0].volume_unit == 'T';
              // }
            }
            this.empty_flag = true;
          } else {
            this.empty_flag = false;
          }
          this.loaded = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  billChange(event, id, idx) {
    if (event.target.files.length == 0) {
      console.log('No file selected!');
      return;
    } else {
      let bill_index = 0;
      let invoice_index = 0;
      const formData: FormData = new FormData();
      this.file = event.target.files;
      for (let f = 0; f < this.file.length; f++) {
        // console.log(this.file[f])
        formData.append(
          'multiple_images',
          this.file.item(f),
          this.file.item(f).name
        );
      }
      formData.append(`booking_id`, id);
      this.ls
        .bill_upload(formData)
        .then((res) => {
          // console.log(res)
          this.ls
            .get_order(id)
            .then((res) => {
              console.log(res);
              if (res.status == 1) {
                // this.myorders[idx] = res.order
                this.documentlist = [];
                if (res.order.bill_documents != null) {
                  if (res.order.bill_documents.length > 0) {
                    this.bill = res.order.bill_documents;
                    // this.bill_flag = true
                    this.myorders[idx].bill_documents =
                      res.order.bill_documents;
                    bill_index = res.order.bill_documents.length;
                  }
                } else {
                  this.bill = [];
                }
                if (res.order.invoice_documents != null) {
                  if (res.order.invoice_documents.length > 0) {
                    // this.invoice = res.order.invoice_documents
                    // this.invoice_flag = true
                    invoice_index = res.order.invoice_documents.length;
                  }
                }
                //  else {
                //   this.invoice = []
                // }

                if (invoice_index >= bill_index) {
                  this.index = invoice_index;
                } else {
                  this.index = bill_index;
                }

                if (this.index > 0) {
                  for (let i = 0; i < this.index; i++) {
                    let doc_obj = {
                      bill: null,
                      invoice: null,
                    };
                    if (this.bill[i]) {
                      doc_obj.bill = this.bill[i];
                    }
                    if (this.invoice[i]) {
                      doc_obj.invoice = this.invoice[i];
                    }
                    this.documentlist.push(doc_obj);
                  }
                }
                // this.bill = res.order.bill_documents
                // this.bill_flag = true
                // this.invoice = res.order.invoice_documents
                // this.invoice_flag = true
                // this.doc_flag=true
                if (this.documentlist.length > 0) {
                  // this.invoice_flag = true
                  // this.bill_flag = true
                  this.doc_flag = true;
                  console.log(this.documentlist);
                  console.log(this.doc_flag);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  invoiceChange(event, id, idx) {
    if (event.target.files.length == 0) {
      console.log('No file selected!');
      return;
    } else {
      let bill_index = 0;
      let invoice_index = 0;
      const formData: FormData = new FormData();
      this.file = event.target.files;
      console.log(this.file.length);
      for (let f = 0; f < this.file.length; f++) {
        // console.log(this.file[f])
        formData.append(
          'multiple_images',
          this.file.item(f),
          this.file.item(f).name
        );
      }
      // console.log(formData)
      formData.append(`booking_id`, id);
      this.ls
        .invoice_upload(formData)
        .then((res) => {
          // console.log(res)
          this.ls
            .get_order(id)
            .then((res) => {
              console.log(res);
              if (res.status == 1) {
                this.documentlist = [];
                // this.myorders[idx] = res.order
                if (res.order.bill_documents != null) {
                  if (res.order.bill_documents.length > 0) {
                    // this.bill = res.order.bill_documents
                    // this.bill_flag = true
                    this.myorders[idx].bill_documents =
                      res.order.bill_documents;
                    bill_index = res.order.bill_documents.length;
                  }
                }
                // else {
                //   this.bill = []
                // }
                if (res.order.invoice_documents != null) {
                  if (res.order.invoice_documents.length > 0) {
                    this.invoice = res.order.invoice_documents;
                    // this.invoice_flag = true
                    this.myorders[idx].invoice_documents =
                      res.order.invoice_documents;
                    invoice_index = res.order.invoice_documents.length;
                  }
                } else {
                  this.invoice = [];
                }

                if (invoice_index >= bill_index) {
                  this.index = invoice_index;
                } else {
                  this.index = bill_index;
                }

                if (this.index > 0) {
                  for (let i = 0; i < this.index; i++) {
                    let doc_obj = {
                      bill: null,
                      invoice: null,
                    };
                    if (this.bill[i]) {
                      doc_obj.bill = this.bill[i];
                    }
                    if (this.invoice[i]) {
                      doc_obj.invoice = this.invoice[i];
                    }
                    this.documentlist.push(doc_obj);
                  }
                }
                // this.bill = res.order.bill_documents
                // this.bill_flag = true
                // this.invoice = res.order.invoice_documents
                // this.invoice_flag = true
                // this.doc_flag=true
                if (this.documentlist.length > 0) {
                  // this.invoice_flag = true
                  // this.bill_flag = true
                  this.doc_flag = true;
                  console.log(this.documentlist);
                  console.log(this.doc_flag);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  open_accordion(obj, idx, flag) {
    this.view_more = false;
    let bill_index = 0;
    let invoice_index = 0;
    this.doc_flag = false;
    this.bill_flag = false;
    this.invoice_flag = false;
    this.documentlist = [];
    this.shipment_details = obj.shipment_details;
    this.actualcost = obj.cost;
    // console.log(obj.pickup_address);
    // console.log(obj.drop_address);
    this.pickup_address = obj.pickup_address;
    this.drop_address = obj.drop_address;
    if (obj.bill_documents != null) {
      if (obj.bill_documents.length > 0) {
        this.bill = obj.bill_documents;
        // this.bill_flag = true
        bill_index = obj.bill_documents.length;
      }
    } else {
      this.bill = [];
    }
    if (obj.invoice_documents != null) {
      if (obj.invoice_documents.length > 0) {
        this.invoice = obj.invoice_documents;
        // this.invoice_flag = true
        invoice_index = obj.invoice_documents.length;
      }
    } else {
      this.invoice = [];
    }

    if (invoice_index >= bill_index) {
      this.index = invoice_index;
    } else {
      this.index = bill_index;
    }

    if (this.index > 0) {
      for (let i = 0; i < this.index; i++) {
        let doc_obj = {
          bill: null,
          invoice: null,
        };
        if (this.bill[i]) {
          doc_obj.bill = this.bill[i];
        }
        if (this.invoice[i]) {
          doc_obj.invoice = this.invoice[i];
        }
        this.documentlist.push(doc_obj);
      }

      if (this.documentlist.length > 0) {
        // this.invoice_flag = true
        // this.bill_flag = true
        this.doc_flag = true;
        console.log(this.documentlist);
        console.log(this.doc_flag);
      }
    }

    if (obj.shipment_details[0].volume_unit == 'INCH') {
      // console.log(obj.shipment_details[0].volume_unit);
      this.volume_unit = 'in';
    } else if (obj.shipment_details[0].volume_unit == 'FEET') {
      this.volume_unit = 'ft';
    } else {
      this.volume_unit = 'cm';
    }
    this.actualadvance = Math.ceil((20 / 100) * obj.cost);
    this.table_flag = true;
    console.log(flag);
    if (!flag) {
      if (!document.getElementById('header').classList.contains('sticky')) {
        document.getElementById('header').classList.add('sticky');
      }
      setTimeout(() => {
        let height = document.getElementById('header').offsetHeight;
        let position =
          document
            .getElementById('accordion-header-' + idx)
            .getBoundingClientRect().top -
          9 +
          window.pageYOffset -
          height;

        window.scrollTo({
          top: position,
          behavior: 'smooth',
        });
      }, 400);
    }
  }

  viewmore() {
    if (this.view_more == false) {
      this.view_more = true;
    } else {
      this.view_more = false;
    }
  }
  download(url, name) {
    this.ls
      .downloadFile(url)
      .then((response) => {
        console.log(response);
        console.log(response.type);

        let blob: any = new Blob([response], {
          type: response.type,
        });
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        //window.location.href = response.url;
        this.downloadUrl(url, name);
        URL.revokeObjectURL(url);
        // fileSaver.saveAs(blob, name)
      })
      .catch((error) => console.log('Error downloading the file'));
  }

  downloadUrl(url: string, fileName: string) {
    const a: any = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.style = 'display: none';
    a.click();
    a.remove();
  }
}
