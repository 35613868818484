<section id="header">
  <div class="container">
    <div class="navbar">
      <div class="logo">
        <a href=""><img src="/assets/images/logo.svg" alt="logo" /></a>
      </div>
      <div
        class="login username_sec inner_login1 {{
          login_flag ? 'd-none' : 'd-flex'
        }}"
      >
        <p (click)="openDialog('login', '')">Login</p>
        <p (click)="openDialog('register', '')">New user</p>
      </div>
      <div class="login username_sec {{ login_flag ? 'd-flex' : 'd-none' }}">
        <div class="inner_login2">
          <p class="username">Hello! {{ username }}</p>
          <div class="inner_login d-flex align-items-center">
            <p routerLink="/myorders" (click)="scrolltop()">My Orders</p>
            <p (click)="log_out()" class="logout">Logout</p>
          </div>
        </div>
      </div>
    </div>
    <div class="video_icon">
      <div class="video_icon_inner">
        <div class="video_img">
          <img
            src="/assets/images/camera_reels.svg"
            alt="video_icon"
            (click)="open_video()"
            (mouseover)="show_tooltip('video')"
            (mouseout)="show_tooltip('video')"
          />
          <div class="tooltip-inner {{ istooltip_video ? 'show' : '' }}">
            Demo videos
          </div>
        </div>
      </div>
    </div>
    <div class="stickybar">
      <!-- <div class="plus-circle">
        <img
          src="/assets/images/plus_circle.svg"
          alt="circle"
          (click)="open_sticky($event)"f
        />
      </div> -->
      <div class="dripple show">
        <!-- {{ open_stick ? 'show' : '' }} -->
        <div>
          <a href="https://wa.me/+918086730010?text=" target="_blank">
            <img
              src="/assets/images/whatsapp.svg"
              alt="whatsapp"
              (click)="open_sticky($event)"
          /></a>
        </div>
        <div>
          <a href="tel:919745197887">
            <img
              src="/assets/images/phone.svg"
              alt="phone"
              (click)="open_sticky($event)"
            />
          </a>
        </div>

        <div class="tooltip-icon">
          <a href="https://wa.me/+918086730010?text=" target="_blank">
            <img
              src="/assets/images/location.svg"
              alt="location"
              (click)="open_sticky($event)"
              (mouseover)="show_tooltip('track')"
              (mouseout)="show_tooltip('track')"
            />
            <div class="tooltip-inner {{ istooltip ? 'show' : '' }}">
              Track My Order
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
