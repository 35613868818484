import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PopupComponent } from './popup/popup.component';
import { AppComponent } from './app.component';
import { MyordersPageComponent } from './myorders-page/myorders-page.component';
import { HomepageComponent } from './homepage/homepage.component';
import { VideoPageComponent } from './video-page/video-page.component';
import { TruckPageComponent } from './truck-page/truck-page.component';
const routes: Routes = [
  // { path: 'reset-password/:token', component: PopupComponent },
  { path: 'videopage', component: VideoPageComponent },
  { path: 'myorders', component: MyordersPageComponent },
  { path: 'homepage', component: HomepageComponent },
   { path: 'truckpage', component: TruckPageComponent },
  { path: '**', component: HomepageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
