import { Component, HostListener, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { HomepageComponent } from '../homepage/homepage.component';
import { LogicService } from '../service/logic.service';
// import { inject } from '@angular/core/testing'
import { DialogData } from '../trainfrom.model';
import { UserService } from '../service/user.service';
import { TokenService } from '../service/token.service';

// import { DialogData } from './trainfrom.model'
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  servicemessage;
  form_type = 'Password';
  button_type = '';
  form_return;
  order_number;
  otp_clicked = false;
  address_submitted = false;
  pass_visiblity = 'visibility_off';
  confirm_visiblity = 'visibility_off';
  otp_request = false;
  otp_message;
  submit_forgot = false;
  show_error = false;
  show_otp_error = false;
  submit_reset = false;
  show_password_error = false;
  passwordmessage;
  transaction_id = '';
  submit_login = false;
  submit_register = false;
  submit_transaction = false;
  success_response;
  success_res = true;
  jwt_token;
  error_message1 = 'No suggestion found';
  registerForm: FormGroup;
  loginForm: FormGroup;
  forgotForm: FormGroup;
  otpLoginForm: FormGroup;
  changeForm: FormGroup;
  trans_Form: FormGroup;
  address_form: FormGroup;
  innerWidth;
  error_message =
    'There was an error happened while fetching data, we will revert back to you shortly, we regret for inconvience';
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    public myapp: HomepageComponent, // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public user: UserService,
    public token: TokenService,
    private route: ActivatedRoute,
    public logic: LogicService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.show_password_error=false
    this.innerWidth = window.innerWidth;
    console.log(this.data);
    if (this.data.message != '') {
      this.error_message1 = this.data.message;
    }
if(location)
    // this.innerWidth = this.innerWidth
    // this.registerForm.reset()
    // this.loginForm.reset()
    this.order_number = localStorage.getItem('order_id');
    console.log(this.innerWidth);
    this.button_type = localStorage.getItem('key_type');
    if (this.button_type == 'changepassword') {
      this.success_res = false;
      this.jwt_token = localStorage.getItem('reset_token');
    } else {
      this.success_res = true;
    }
    this.registerForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z ]*$/),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
        ),
      ]),
      phone: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
      ]),
      pin: new FormControl('', [Validators.required, Validators.minLength(6)]),
      address: new FormControl('', [Validators.required]),
      gst: new FormControl('', [Validators.required, Validators.minLength(15)]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    });
    this.registerForm.reset();

    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      password: new FormControl('', [Validators.required]),
    });

    this.trans_Form = new FormGroup({
      trans_id: new FormControl('', [Validators.required]),
    });

    this.address_form = new FormGroup({
      company_name: new FormControl('', [Validators.required]),
      address_one: new FormControl('', [Validators.required]),
      address_two: new FormControl(''),
      city: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      district: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      state: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      contact_person: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      contact_number: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      pin1: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
      company_name_two: new FormControl('', [Validators.required]),
      address_one_one: new FormControl('', [Validators.required]),
      address_two_two: new FormControl(''),
      city_two: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      district_two: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      state_two: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      contact_person_two: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]+'),
      ]),
      contact_number_two: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.minLength(10),
        Validators.maxLength(13),
      ]),
      pin2: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[0-9]+$/),
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
    });

    this.trans_Form.reset();
    this.address_form.reset();

    this.otpLoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
      otp: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
    this.forgotForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ),
      ]),
    });
    this.changeForm = new FormGroup({
      new_password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
    });
  }

  get transactionControl() {
    return this.trans_Form.controls;
  }

  get addressControl() {
    return this.address_form.controls;
  }
  get regitserControl() {
    return this.registerForm.controls;
  }

  get loginControl() {
    return this.loginForm.controls;
  }

  get otpLoginControl() {
    return this.otpLoginForm.controls;
  }

  get forgotControl() {
    return this.forgotForm.controls;
  }

  get changeControl() {
    return this.changeForm.controls;
  }
  reset_address_form() {
    this.address_form.reset();
    this.address_submitted = false;
  }
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }

  select_form(e) {
    console.log(e);
    this.form_type = e;
    this.form_return = this.form_type;
    console.log(this.form_return, 'select');
    if (e == 'Password') {
      this.otpLoginForm.reset();
      this.otp_request = false;
      this.show_otp_error = false;
    } else {
      this.loginForm.reset();
      this.show_password_error = false;
    }
  }

  openDialog(e): void {
    if (e == 'login') {
      this.button_type = 'login';
      this.loginForm.reset();
      this.otpLoginForm.reset();
      this.submit_login = false;
      this.otp_request = false;
      this.show_otp_error = false;
      this.show_password_error = false;
      if (this.form_return != null) {
        this.form_type = this.form_return;
      }

      console.log(this.form_type, 'open');
    } else if (e == 'register') {
      this.button_type = 'register';
      this.registerForm.reset();
      this.show_error = false;
      this.submit_register = false;
    } else if (e == 'transaction') {
      this.button_type = 'transaction';
    } else {
      this.button_type = 'forgotpassword';
    }
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (!this.success_res) {
        window.location.href = '/';
      }
      // this.animal = result
    });
  }

  show_pass(e: any, field: string) {
    if (field == 'password') {
      if (this.pass_visiblity != 'visibility_off') {
        this.pass_visiblity = 'visibility_off';
      } else {
        this.pass_visiblity = 'visibility';
      }
    } else {
      if (this.confirm_visiblity != 'visibility_off') {
        this.confirm_visiblity = 'visibility_off';
      } else {
        this.confirm_visiblity = 'visibility';
      }
    }
  }

  register_user() {
    this.loginForm.reset()
    console.log(this.registerForm);
    this.submit_register = true;

    if (
      this.registerForm.value.password !=
      this.registerForm.value.confirm_password
    ) {
      this.registerForm.controls.confirm_password.setErrors({
        notEquivalent: true,
      });
    }
    if (this.registerForm.status == 'VALID') {
      this.user.load_trigger(true);
      let data = {
        name: this.registerForm.value.name,
        email: this.registerForm.value.email.toLowerCase(),
        phone: this.registerForm.value.phone,
        password: this.registerForm.value.password,
        address: this.registerForm.value.address,
        pin: this.registerForm.value.pin,
        gst_number: this.registerForm.value.gst,
      };
      this.user
        .register(data)
        .then((res) => {
          if (res.status == 1) {
            this.user.load_trigger(false);
            console.log(res);
            this.button_type = 'login';
            this.form_type = 'Password';
            console.log('registered successfully');
            this.registerForm.reset();
          } else if (res.phone_unique || res.email_unique) {
            this.user.load_trigger(false);
            // console.log(res)
            this.show_error = true;
            this.servicemessage = res.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('invalid form');
    }
  }

  send_otp() {
    this.otp_clicked = true;
    this.submit_login = true;
    console.log(this.otpLoginControl.email);
    let data = {
      email: this.otpLoginForm.value.email,
    };
    if (this.otpLoginControl.email.errors == null) {
      this.user.load_trigger(true);
      this.user.send_otp(data).then((res) => {
        console.log(res);
        if (res.status == 1) {
          this.otp_request = true;
          this.show_otp_error = false;
          this.user.load_trigger(false);
        } else if (res.email_exist) {
          this.show_otp_error = true;
          this.user.load_trigger(false);
          this.otp_message = res.message;
          console.log('error');
        }
      });
    } else {
      console.log('please enter email');
    }
  }

  login() {
    console.log(this.loginForm);
    this.submit_login = true;
    this.show_password_error = false;
    this.show_otp_error = false;
    let data = {
      email:
        this.form_type == 'Password'
          ? this.loginForm.value.email.toLowerCase()
          : this.otpLoginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
      otp: this.otpLoginForm.value.otp,
      login_type: this.form_type,
    };
    if (this.form_type == 'Password') {
      if (this.loginForm.status == 'VALID') {
        this.user.load_trigger(true);
        this.user
          .login(data)
          .then((res) => {
            if (res.status == 1) {
              this.user.load_trigger(false);
              this.token.saveToken(res.token);
              this.token.saveUser(res.user);
              // this.myapp.login_success()
              this.user.login_success();
              // this.user.loginFn()
              this.dialogRef.close();
            } else if (res.email_exist || res.pass_match) {
              this.user.load_trigger(false);
              this.show_password_error = true;
              this.passwordmessage = res.message;
            }
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('invalid login form');
      }
    } else {
      if (this.otpLoginForm.status == 'VALID') {
        this.user.load_trigger(true);
        this.user
          .login(data)
          .then((res) => {
            console.log(res);
            if (res.status == 1) {
              this.user.load_trigger(false);
              this.token.saveToken(res.token);
              this.token.saveUser(res.user);
              this.user.login_success();
              // this.myapp.logged_in = true
              // this.myapp.login_success()
              // this.user.loginFn()
              this.dialogRef.close();
            } else if (res.otp_error == 1) {
              this.user.load_trigger(false);
              console.log(res.message);
              this.otp_message = res.message;
              this.show_otp_error = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('invalid otp form');
      }
    }
  }

  update_payment() {
    this.submit_transaction = true;
    if (this.trans_Form.status == 'VALID') {
      if (this.transaction_id != '' && this.transaction_id != null) {
        localStorage.setItem('transaction_id', this.transaction_id);
        this.dialogRef.close();
        this.myapp.update_transaction();
      }
    } else {
      console.log('transaction form invalid');
    }
  }

  reload() {
    location.reload();
  }

  forgot_password() {
    console.log(this.forgotForm);
    this.submit_forgot = true;
    if (this.forgotForm.status == 'VALID') {
      this.user
        .forgot_password({ email: this.forgotForm.value.email })
        .then((res) => {
          if (res.status == 1) {
            this.button_type = 'success';
            this.success_res = false;
            this.success_response =
              'Password reset link has been sent to your registered email address';
          } else {
            this.show_error = true;
            this.servicemessage = res.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('inavlid forgot form');
    }
  }

  reset_password() {
    this.submit_reset = true;
    if (
      this.changeForm.value.new_password !=
      this.changeForm.value.confirm_password
    ) {
      this.changeForm.controls.confirm_password.setErrors({
        notEquivalent: true,
      });
    }
    if (this.changeForm.status == 'VALID') {
      this.user.load_trigger(true)
      this.user
        .reset_password(
          {
            old_password: this.changeForm.value.new_password,
            new_password: this.changeForm.value.confirm_password,
          },
          this.jwt_token
        )
        .then((res) => {
          console.log(res);
          if (res.status == 1) {
            this.button_type = 'success';
            this.success_res = false;
            this.success_response = 'Password changed successfully';
          } else {
            this.show_error = true;
            this.servicemessage = res.message;
          }
          this.user.load_trigger(false)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log('inavlid forgot form');
    }
  }

  popup_close() {
    this.dialogRef.close();
    if ((this.button_type = 'success')) {
      location.reload();
      console.log('success');
    }
    if (!this.success_res) {
      window.location.href = '/';
    }
  }

  ValidateAlpha(evt) {
    var keyCode = evt.which ? evt.which : evt.keyCode;
    if (
      (keyCode < 65 || keyCode > 90) &&
      (keyCode < 97 || keyCode > 123) &&
      keyCode != 32
    )
      return false;
    return true;
  }
  update_address() {
    this.address_submitted = true;
    let id = localStorage.getItem('booking_id');
    // this.openDialog('transaction')
    // this.myapp.openDialog('success', '')
    let data = {
      booking_id: id,
      pickup_address: {
        'company name': this.address_form.value.company_name,
        'address line 1': this.address_form.value.address_one,
        'address line 2': this.address_form.value.address_two,
        city: this.address_form.value.city,
        disrict: this.address_form.value.district,
        state: this.address_form.value.state,
        'contact person': this.address_form.value.contact_person,
        'contact number': this.address_form.value.contact_number,
        pincode: this.address_form.value.pin1,
      },
      drop_address: {
        'company name': this.address_form.value.company_name_two,
        'address line 1': this.address_form.value.address_one_one,
        'address line 2': this.address_form.value.address_two_two,
        'contact person': this.address_form.value.contact_person_two,
        'contact number': this.address_form.value.contact_number_two,
        city: this.address_form.value.city_two,
        disrict: this.address_form.value.district_two,
        state: this.address_form.value.state_two,
        pincode: this.address_form.value.pin2,
      },
    };
    console.log(this.address_form);
    console.log(data);
    if (this.address_form.status == 'VALID') {
      this.logic
        .update_address(data)
        .then((res) => {
          if (res.status == 1) {
            this.dialogRef.close();
            // this.button_type = 'success'
            this.success_res = true;
            this.myapp.openDialog('success', '');
          } else {
            this.show_error = true;
            this.servicemessage = res.message;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
