import {   Component,
  HostListener,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit, } from '@angular/core';

@Component({
  selector: 'app-truck-page',
  templateUrl: './truck-page.component.html',
  styleUrls: ['./truck-page.component.scss']
})
export class TruckPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
