<footer id="footer" class="py-3">
  <div class="container">
    <div class="row">
      <div
        class="col-md-6 d-flex justify-content-sm-center justify-content-md-center"
      >
        <p class="m-0">Copyright © 2021 chaavie.com. All rights reserved.</p>
      </div>
      <div
        class="col-md-6 d-flex justify-content-sm-center justify-content-md-center"
      >
        <span>Designed by</span>
        <p class="m-0">
          <a href="http:\\www.6solve.com" class="web_link" target="_blank">
            6Solve IT Services
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>
<section class="sticky_footer">
  <div class="trackers">
    <div class="my-2">
      <a href="https://wa.me/+919745197887?text=" target="_blank">
        <img src="/assets/images/whatsapp.svg" alt="whatsapp"
      /></a>
    </div>
    <div class="divider"><hr /></div>
    <div class="my-2">
      <a href="tel:919745197887">
        <img src="/assets/images/phone.svg" alt="phone" />
      </a>
    </div>
    <div class="divider"><hr /></div>
    <div class="my-2">
      <a href="https://wa.me/+919745197887?text=" target="_blank"
        ><img src="/assets/images/location.svg" alt="location"
      /></a>
    </div>
    <div class="divider"><hr /></div>
    <div class="videoicon">
      <div class="video_icon_inner">
        <div class="video_img" >
          <img src="/assets/images/camera_reels.svg" (click)=" open_video()" alt="">
        </div>
      </div>
    </div>
  </div>
</section>
