import {
  Component,
  HostListener,
  Inject,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { title } from 'process';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Observable, from, Subject, of } from 'rxjs';
import {
  MyErrorStateMatcher,
  DialogData,
  environment,
} from '../trainfrom.model';
import * as mapboxgl from 'mapbox-gl';
// import { MatAutocompleteTrigger } from '@angular/material'
import { TokenService } from '../service/token.service';
import { UserService } from '../service/user.service';
// import PopupComponent from './popup/popup.component'

import {
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PopupComponent } from '../popup/popup.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogicService } from '../service/logic.service';
import { DateAdapter } from '@angular/material/core';
// import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  username;
  istooltip = false;
  istooltip_video=false
  open_stick = false;
  login_flag: boolean = false;
  login_flag$: Observable<boolean>;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewPortScroller: ViewportScroller,
    public dialog: MatDialog,
    private elref: ElementRef,
    private ls: LogicService,
    private token: TokenService,
    public user: UserService,
    public dialogRef: MatDialogRef<PopupComponent>,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute, //
    public datepicker: ElementRef,
    public datepipe: DatePipe,
    public dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    let token = this.token.getUser();
    if (token != null) {
      this.login_flag = true;
      this.username = token.name;
      console.log('login_flag', this.login_flag);
    }
    this.login_flag$ = this.user.listSize;
    this.login_flag$.subscribe((result) => {
      console.log(result);
      this.login_flag = result;
      this.username = this.token.getUser().name;
    });

    let current_url = window.location.href.split('reset-password/');
    console.log(current_url);
    if (current_url.length > 1) {
      localStorage.setItem('reset_token', current_url[1]);
      this.openDialog('changepassword', '');
    }

    if (this.user.subsVar==undefined) {
      this.user.subsVar = this.user.
      invokeopen_video.subscribe(() => {
        this.open_video();
      });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.documentElement.scrollTop > 0.2) {
      document.getElementById('header').classList.add('sticky');
    }

    if (window.scrollY == 0) {
      document.getElementById('header').classList.remove('sticky');
    }
  }
  openDialog(e, msg): void {
    localStorage.removeItem('key_type');
    localStorage.setItem('key_type', e);
    this.dialogRef = this.dialog.open(PopupComponent, {
      width: e == 'success' ? '40rem' : '23rem',
      disableClose: e == 'success' || e == 'transaction' ? true : false,
      data: {
        message: msg,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  closeaccount = this.closesticky.bind(this);
  closesticky() {
    this.open_stick = false;
    document.removeEventListener('click', this.closeaccount);
  }

  open_sticky(e) {
    if (this.open_stick == false) {
      this.open_stick = true;
      e.stopPropagation();
      document.addEventListener('click', this.closeaccount);
    } else {
      this.open_stick = false;
    }
  }

  remove_sticky() {
    this.open_sticky(false);
  }


  show_tooltip(type) {
    if(type=='track'){
      if (this.istooltip == false) {
        this.istooltip = true;
      } else {
        this.istooltip = false;
      }
    }else{
      if (this.istooltip_video == false) {
        this.istooltip_video = true;
      } else {
        this.istooltip_video = false;
      }
    }

  }

  log_out() {
    this.token.signOut();
    this.login_flag = false;
    window.location.href = '';
    // location.reload();
  }

  open_video() {
    if (this.login_flag) {
      location.href = '/videopage';
    } else {
      this.openDialog('login','')
    }
  }

  login_success() {
    console.log('logged_in');
    console.log(this.login_flag);
    this.login_flag = true;
    // this.login_flag = false
    this.ref.detectChanges();
    console.log(this.login_flag);
    location.href = '/homepage';
    // this.subscription = this.user.getEmittedValue()
    //   .subscribe(item => this.login_flag=item);
  }
  scrolltop() {
    setTimeout(() => {
      window.scroll(0, 0);
    }, 500);
  }
}
