import { Injectable } from '@angular/core'

const TOKEN_KEY = 'token'
const USER_KEY = 'customer'

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor() {}

  signOut(): void {
    localStorage.clear()
    window.sessionStorage.clear()
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.setItem(TOKEN_KEY, token)
    window.sessionStorage.removeItem(TOKEN_KEY)
    window.sessionStorage.setItem(TOKEN_KEY, token)
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY)
    // return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    localStorage.removeItem(USER_KEY)
    localStorage.setItem(USER_KEY, JSON.stringify(user))
    window.sessionStorage.removeItem(USER_KEY)
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user))
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem(USER_KEY))
    // return JSON.parse(sessionStorage.getItem(USER_KEY));
  }
}
