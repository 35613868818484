import {
  Component,
  HostListener,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { title } from 'process';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Observable, from, Subject, of } from 'rxjs';
import {
  MyErrorStateMatcher,
  DialogData,
  environment,
} from '../trainfrom.model';
import * as mapboxgl from 'mapbox-gl';
import { HeaderComponent } from '../header/header.component';
import { FooterComponent } from '../footer/footer.component';
// import { MatAutocompleteTrigger } from '@angular/material'
import { TokenService } from '../service/token.service';
import { UserService } from '../service/user.service';
// import PopupComponent from './popup/popup.component'
import {
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PopupComponent } from '../popup/popup.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogicService } from '../service/logic.service';
import { DateAdapter } from '@angular/material/core';
// import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  @ViewChild('table_div') private myScrollContainer: ElementRef;
  @ViewChild('progress-table') private mymapoffsetheight: ElementRef;
  /* Mapbox */
  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 13.0827;
  lng = 80.2707;
  /* Mapbox  end*/
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  istooltip = false;
  messsage = '';
  // count_with_unit
  isChecked = false;
  remove_blur = false;
  from_select = false;
  to_select = false;
  istruck_details:boolean=false;
  error = false;
  price_obj: any = {};
  isActive = false;
  available = true;
  date_place = true;
  booking_id;
  select_date;
  open_stick = false;
  search_submitted = false;
  check_submitted = false;
  from_flag = false;
  unit_of_pack = 'packages';
  unit_pack = '';
  to_flag = false;
  table_count;
  username;
  select_flag = false;
  size_value;
  pre_type;
  loader_flag$: Observable<boolean>;
  loader = false;
  nodataavail = false;
  toggled = 'qrcode';
  Total_amount = '18500';
  booking_advance = '10000';
  from_location: any = {};
  to_location: any = {};
  from_list: any = [];
  to_list: any = [];
  public button_type = '';
  from_index = 0;
  to_index = 0;
  amount = 2500;
  selectedValue = {
    value: 'carton',
    viewValue: 'Carton',
    image: 'assets/images/box.svg',
  };
  show_map = false;
  show_message = false;
  show_table = false;
  show_payment = false;
  button_none = false;
  minDate;
  month;
  day;
  Nill = '---';
  year;
  ispay = false;
  marker_array = [];
  selected_image;
  min_date;
  directions;
  jwt_token;
  trainForm: FormGroup;
  cargoform: FormGroup;
  loadForm: FormGroup;
  rows: FormArray;
  itemForm: FormGroup;
  bank_name = 'Some bank of india';
  account = '6789987656579';
  ifsccode = 'BIN9879870';
  type_arr = [];
  totalvolume = 500;
  totalload = 1500;
  // matcher = new MyErrorStateMatcher()
  public innerWidth: any;
  unit_arr = [];
  length_unit = 'CM';
  weight_unit = 'KG';
  unit_length = 'cm';
  unit_weight = 'kg';
  table_weight = 0;
  table_volume = 0;
  total_flag = false;
  volume_arr = [];
  refresh_clicked = false;
  colors = [
    '#eb1460',
    '#ff5505',
    '#ffec16',
    '#3d4db7',
    '#640936',
    '#00a6f6',
    '#ff9800',
    '#f6402c',
    '#46af4a',
    '#88c440',
  ];
  //
  toppings = new FormControl();
  toppingList: string[] = [
    'Extra cheese',
    'Mushroom',
    'Onion',
    'Pepperoni',
    'Sausage',
    'Tomato',
  ];
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private viewPortScroller: ViewportScroller,
    public dialog: MatDialog,
    private elref: ElementRef,
    private ls: LogicService,
    private token: TokenService,
    public user: UserService,
    public dialogRef: MatDialogRef<PopupComponent>,
    private route: ActivatedRoute, //
    public datepicker: ElementRef,
    public datepipe: DatePipe,
    public dateAdapter: DateAdapter<Date>,
    private fb: FormBuilder
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.loadForm = this.fb.group({
      type: [null, Validators.required],
      content: [null, Validators.required],
      l: [null, Validators.required],
      w: [null, Validators.required],
      h: [null, Validators.required],
      weight: [null, Validators.required],
      count: [null, Validators.required],
      totalload: [null],
      totalvolume: [null],
    });
    this.rows = this.fb.array([]);
    for (let j = 0; j < 10; j++) {
      this.type_arr.push({ type: 'Carton', image: 'assets/images/box.svg' });
    }
    this.rows.push(this.createItemFormGroup());

    // window.addEventListener('mouseover',this.remove_sticky.bind(this));
  }

  packages = [
    {
      value: 'carton',
      viewValue: 'Carton',
      image: 'assets/images/box.svg',
    },
    { value: 'sack', viewValue: 'Sack', image: 'assets/images/sack.svg' },
    { value: 'crate', viewValue: 'Crate', image: 'assets/images/crate.svg' },
  ];

  ngOnInit(): void {
    // this.selected_image = this.packages[0].image;
    // this.selectedValue = this.packages[0].viewValue;
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loadForm.addControl('rows', this.rows);
    // console.log('load form');
    // console.log(this.loadForm);


    this.loader_flag$ = this.user.loader;
    this.loader_flag$.subscribe((result) => {
      console.log(result);
      this.loader = result;
      this.loader_trigger(result);
    });

    this.trainForm = new FormGroup({
      from: new FormControl('', [Validators.required]),
      to: new FormControl('', [Validators.required]),
      date: new FormControl(''),
    });
    this.cargoform = new FormGroup({
      type: new FormControl('', [Validators.required]),
      package_type: new FormControl('', [Validators.required]),
      length: new FormControl('', [Validators.required]),
      breath: new FormControl('', [Validators.required]),
      height: new FormControl('', [Validators.required]),
      count: new FormControl('', [Validators.required]),
      load: new FormControl('', [Validators.required]),
      unit: new FormControl(),
    });
    this.cargoform.controls.unit.setValue('Kg');
    this.innerWidth = window.innerWidth;
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 2);
    this.month = this.minDate.getMonth() + 1; // getMonth() is zero-based
    this.day = this.minDate.getDate();
    this.year = this.minDate.getFullYear();
    if (this.month < 10) {
      this.month = '0' + this.month.toString();
    }

    if (this.day < 10) {
      this.day = '0' + this.day.toString();
    }
    this.min_date = this.year + '-' + this.month + '-' + this.day;
    this.select_date = this.min_date;
    console.log(this.min_date);
    console.log(this.select_date, 'oninit-selectdate');

    /*Mapbox*/
    // Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl())
    //document.addEventListener('click', this.remove_class)

    this.user
      .get_config('account_number')
      .then((res) => {
        if (res.status) {
          this.account = res.data.content_value;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.user
      .get_config('IFSC_code')
      .then((res) => {
        if (res.status) {
          this.ifsccode = res.data.content_value;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.user
      .get_config('bank_name')
      .then((res) => {
        if (res.status) {
          this.bank_name = res.data.content_value;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /*mapbox*/
    mapboxgl.accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      // zoom: 10,
      center: [78.9629, 20.5937],
    });
    setTimeout(() => {
      window.scroll(0, 0);
    }, 500);
  }

  onAddRow() {
    this.check_submitted = true;
    if (this.loadControl.status == 'VALID') {
      this.check_submitted = false;
      this.rows.push(this.createItemFormGroup());
      var objDiv = document.getElementById('table_div');
      setTimeout(() => {
        console.log(document.getElementById('table_div').scrollHeight);
        // objDiv.scrollTop = objDiv.scrollHeight
        objDiv.scroll({
          top: objDiv.scrollHeight,
          left: 0,
          // behavior: 'smooth',
        });
      }, 100);
      console.log(this.loadControl);
    }

    // var objDiv = document.getElementById('table_body');

    // objDiv.scrollTop = objDiv.scrollHeight;
    // console.log(
    //   objDiv.scrollHeight
    //   // this.loadForm.addControl('rows', this.rows)
    // );
  }
  onRemoveRow(rowIndex: number) {
    if (this.rows.length > 1) {
      this.rows.removeAt(rowIndex);
      this.type_arr.splice(rowIndex, 1);
      this.calculation();
    }

    // console.log('removed ', this.rows)
  }
  createItemFormGroup(): FormGroup {
    return this.fb.group({
      type: [null, Validators.required],
      content: [null, Validators.required],
      l: [null, Validators.required],
      w: [null, Validators.required],
      h: [null, Validators.required],
      weight: [null, Validators.required],
      count: [null, Validators.required],
      totalload: null,
      totalvolume: null,
    });
    console.log('created');
  }
  /** Error when invalid control is dirty, touched, or submitted. */
  get errorControl() {
    // console.log(this.registerForm.controls)
    // console.log(this.trainForm.controls)
    return this.trainForm.controls;
  }

  get loadControl() {
    // console.log(this.registerForm.controls)
    //console.log(this.loadForm.controls.rows)
    return this.loadForm.controls.rows;
  }

  calculation() {
    console.log('calculation');
    let total_weight = 0;
    let total_volume = 0;
    let total_count = 0;
    let lenth_convertion, weight_convertion;
    if (this.length_unit == 'CM') {
      lenth_convertion = 0.01;
      this.unit_length = 'cm';
    } else if (this.length_unit == 'INCH') {
      lenth_convertion = 0.0254;
      this.unit_length = 'in';
    } else if (this.length_unit == 'FEET') {
      lenth_convertion = 0.3048;
      this.unit_length = 'ft';
    }

    if (this.weight_unit == 'KG') {
      weight_convertion = 1;
      this.unit_weight = 'kg';
    } else if (this.weight_unit == 'TONNE') {
      weight_convertion = 1000;
      this.unit_weight = 'T';
    }

    var formArray = this.loadForm.get('rows') as FormArray;
    for (let i in this.loadForm.value.rows) {
      // console.log(this.loadForm.value.rows[i])
      if (
        this.loadForm.value.rows[i].count != '' &&
        this.loadForm.value.rows[i].count != null
      ) {
        total_count = total_count + parseInt(this.loadForm.value.rows[i].count);
        if (
          this.loadForm.value.rows[i].h != '' &&
          this.loadForm.value.rows[i].h != null &&
          this.loadForm.value.rows[i].l != '' &&
          this.loadForm.value.rows[i].l != null &&
          this.loadForm.value.rows[i].w != '' &&
          this.loadForm.value.rows[i].w != null
        ) {
          let volume =
            parseFloat(this.loadForm.value.rows[i].h) *
            lenth_convertion *
            parseFloat(this.loadForm.value.rows[i].l) *
            lenth_convertion *
            parseFloat(this.loadForm.value.rows[i].w) *
            lenth_convertion;

          formArray
            .at(parseInt(i))
            .get('totalvolume')
            .setValue(
              parseFloat(
                (volume * parseInt(this.loadForm.value.rows[i].count)).toFixed(
                  2
                )
              )
            );
          total_volume =
            total_volume + volume * parseInt(this.loadForm.value.rows[i].count);

          // console.log(volume)
          // console.log(parseInt(this.loadForm.value.rows[i].count))
          // console.log(lenth_convertion)
          // this.loadForm.controls.rows[i]
          //   .get('totalvolume')
          //   .setValue(volume * parseInt(this.loadForm.value.rows[i].count))
          // this.loadForm.controls.rows.controls[i]
          //   .get('totalvolume')
          //   .setValue(volume * parseInt(this.loadForm.value.rows[i].count))
          // =
          //   volume * parseInt(this.loadForm.value.rows[i].count)
        } else {
          formArray.at(parseInt(i)).get('totalvolume').setValue(null);
        }

        if (
          this.loadForm.value.rows[i].weight != '' &&
          this.loadForm.value.rows[i].weight != null
        ) {
          formArray
            .at(parseInt(i))
            .get('totalload')
            .setValue(
              parseFloat(
                (
                  parseInt(this.loadForm.value.rows[i].count) *
                  parseFloat(this.loadForm.value.rows[i].weight)
                ).toFixed(2)
              )
            );
          total_weight =
            total_weight +
            parseInt(this.loadForm.value.rows[i].count) *
              parseFloat(this.loadForm.value.rows[i].weight);
          // this.loadForm.controls.rows[i].totalload =
          //   parseInt(this.loadForm.value.rows[i].count) *
          //   parseInt(this.loadForm.value.rows[i].weight)
        } else {
          formArray.at(parseInt(i)).get('totalload').setValue(null);
        }
      } else {
        formArray.at(parseInt(i)).get('totalload').setValue(null);
        formArray.at(parseInt(i)).get('totalvolume').setValue(null);
        // this.loadForm.value.rows[i].totalload = null
        // this.loadForm.value.rows[i].totalvolume = null
      }
    }

    if (total_volume > 0 && total_weight > 0) {
      this.table_count = total_count;
      this.table_weight = parseFloat(total_weight.toFixed(2));
      this.table_volume = parseFloat(total_volume.toFixed(2));
      this.total_flag = true;
    } else {
      this.table_count = 0;
      this.table_weight = 0;
      this.table_volume = 0;
    }
  }

  get_image(e, i) {
    // console.log(e)
    console.log(this.type_arr);
    let obj = {
      type: e.viewValue,
      image: e.image,
    };
    this.type_arr[i] = obj;

    console.log(this.type_arr[i]);
    // this.selected_image = e.image
    // this.selectedValue = e.viewValue
  }

  loader_trigger(flag) {
    if (flag) {
      this.document.getElementById('loader').style.display = 'flex';
    } else {
      this.document.getElementById('loader').style.display = 'none';
    }
  }

  paynow() {
    let user = this.token.getUser();
    let data = {
      train_id:
        this.price_obj.transport_type == 'road'
          ? null
          : this.price_obj.vehicle_id,
      // order_id: "",
      // transaction_id: '',
      // status: 'pending',
      email: user.email,
      user_name: user.name,
      from_location: this.from_location.place_name,
      to_location: this.to_location.place_name,
      cargo: '',
      vehicle_type: this.price_obj.vehicle,
      booking_date: this.price_obj.date,
      count: this.table_count,
      from_station: this.price_obj.from_station,
      to_station: this.price_obj.to_station,
      train_number:
        this.price_obj.transport_type == 'road'
          ? null
          : this.price_obj.train_number,
      train_type:
        this.price_obj.transport_type == 'road' ? null : this.price_obj.vehicle,
      truck: this.price_obj.truck,
      cost: this.price_obj.travel_charge,
      booking_weight: this.price_obj.load,
      booking_volume: this.price_obj.volume,
      available_weight: this.price_obj.available_weight,
      available_volume: this.price_obj.available_volume,
      distance: this.price_obj.distance,
      code: this.price_obj.code,
      load: this.price_obj.load,
      travel_type: this.price_obj.transport_type,
      vehicle_id: this.price_obj.vehicle_id,
      user_id: user.id,
      package_type: '',
      type: '',
      length: null,
      breadth: null,
      height: null,
      product_detail: this.volume_arr,
      // payment_status: 'pending',
    };

    this.ls
      .paying(data)
      .then((res) => {
        if (res.status) {
          this.ispay = true;
          localStorage.removeItem('booking_id');
          localStorage.setItem('booking_id', res.data.id);
          this.booking_id = res.data.id;
        }
      })
      .catch((err) => {
      this.ispay = false;
        console.log(err);
      });
    // this.ispay = true;
  }
  removeclass() {
    if ((this.date_place = true)) {
      this.date_place = false;
    }
  }
  get cargoControl() {
    if (this.cargoform.value.package_type != null) {
      this.unit_of_pack = this.cargoform.value.package_type + 's';
    }

    return this.cargoform.controls;
  }
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }

  // from = new FormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  iteration: any = [{ color: 'Product' }];

  availabilitylist: any;

  package: any = [
    {
      percent: '10',
      show: true,
    },
    {
      percent: '10',
      show: true,
    },
    {
      percent: '0',
      show: false,
    },
    {
      percent: '50',
      show: true,
    },
    {
      percent: '0',
      show: false,
    },
    {
      percent: '10',
      show: true,
    },
    {
      percent: '0',
      show: false,
    },
    {
      percent: '10',
      show: true,
    },
    {
      percent: '0',
      show: false,
    },
    {
      percent: '10',
      show: true,
    },
    {
      percent: '0',
      show: false,
    },
    {
      percent: '30',
      show: true,
    },
  ];
  wedolist = [
    {
      img_url: '/assets/images/truck.svg',
      title: 'Faster',
      text: 'Getting goods to the recipients faster',
    },
    {
      img_url: '/assets/images/efficiant.svg',
      title: 'Efficient',
      text: 'Lean processes and technology to improve end to end efficiency',
    },
    {
      img_url: '/assets/images/setting.svg',
      title: 'Sustainable',
      text: 'Healthy, and dynamic balance between man, machines and technology',
    },
    {
      img_url: '/assets/images/cost.svg',
      title: 'Cost Effective',
      text: 'Multi-modal Logistics, Technology and Economy of sharing - pay less for better',
    },
  ];

  calculate_size(e, event) {
    if (e == 'true') {
      // document.getElementById('size_input').removeEventListener('click')
      // document.getElementById('size_input').removeAttribute('onclick')
      document.getElementById('size_input').classList.add('show');
      console.log('focus');
    } else {
      // document.getElementById('size_input').createAttribute('onclick')
      // document.getElementById('size_input').addEventListener('click')
      document.getElementById('size_input').classList.remove('show');
      console.log('out');
    }
  }
  dimension_cal() {
    let length = 0;
    let height = 0;
    let breadth = 0;
    if (
      this.cargoform.value.breath != '' &&
      this.cargoform.value.breath != null
    ) {
      breadth = this.cargoform.value.breath;
    }
    if (
      this.cargoform.value.height != '' &&
      this.cargoform.value.height != null
    ) {
      height = this.cargoform.value.height;
    }
    if (
      this.cargoform.value.length != '' &&
      this.cargoform.value.length != null
    ) {
      length = this.cargoform.value.length;
    }
    if (length != 0 && height != 0 && breadth != 0) {
      this.size_value = length + '*' + breadth + '*' + height;
    } else {
      this.size_value = '';
    }
    // console.log(this.cargoform)
  }

  // remove_class(event) {
  //   let arr = ['size_input', 'input_size'];
  //   if (!arr.includes(event.target.id)) {
  //     document.getElementById('size_input').classList.remove('show');
  // let length = 0
  // let height = 0
  // let breadth = 0
  // if (this.cargoform.value.breath != '') {
  //   breadth = this.cargoform.value.breath
  // }
  // if (this.cargoform.value.height != '') {
  //   height = this.cargoform.value.height
  // }
  // if (this.cargoform.value.length != '') {
  //   length = this.cargoform.value.length
  // }
  // if (length != 0 && height != 0 && breadth != 0) {
  //   this.size_value = length + '*' + breadth + '*' + height
  // } else {
  //   this.size_value = ''
  // }
  //   }
  // }

  select(e) {
    console.log(e);
    if (e.target.value == '') {
      this.date_place = true;
    } else {
      this.date_place = false;
    }
  }

  toggleAccordian(event) {
    this.search_submitted = true;
    this.remove_blur = false;
    this.show_message = false;
    const element = event.target.parentElement;
    // console.log(this.errorControl);
    // console.log(this.from_list.length);
    // console.log(this.to_list.length);
    this.trainForm.value.date = this.datepipe.transform(
      this.trainForm.value.date,
      'yyyy-MM-dd'
    );
    console.log(this.trainForm.value.date);
    if (this.trainForm.value.date != null) {
      this.select_date = this.trainForm.value.date;
      console.log(this.select_date, 'selected date');
      // if (this.min_date > this.select_date) {
      //   this.trainForm.controls.date.setErrors({
      //     invalid_date: true,
      //   })
      // }
    }

    if (
      this.trainForm.status == 'VALID' &&
      this.loadControl.status == 'VALID'
    ) {
      if (this.from_select && this.to_select) {
        // if (!this.isActive) {
        //   this.isActive = false;
        //   this.show_map = true;
        // } else {
        //   // this.isActive = false
        //   // this.show_map = false
        //   console.log('map refreshed');
        // }
        this.show_message = false;
        const panel = this.document.getElementById('panel'); //element.nextElementSibling
        if (panel.style.maxHeight) {
          // panel.style.maxHeight = null
        } else {
          panel.style.maxHeight = 2200 + 'px';
        }

        // this.map = new mapboxgl.Map()
        // Create a default Marker and add it to the map.
        let marker1, marker2;
        this.marker_array.forEach((element) => {
          element.remove();
        });

        marker1 = new mapboxgl.Marker({ color: 'green' })
          .setLngLat(this.from_location.center)
          .addTo(this.map);
        this.marker_array.push(marker1);

        // Create a default Marker, colored black, rotated 45 degrees.
        marker2 = new mapboxgl.Marker({ color: 'red' })
          .setLngLat(this.to_location.center)
          .addTo(this.map);
        this.marker_array.push(marker2);
        // var group = new mapboxgl.featureGroup([marker1, marker2])

        // this.map.fitBounds(group.getBounds())
        // var bounds = latLngBounds(this.marker_array)
        console.log(marker1._lngLat.lat, marker1._lngLat.lng);
        console.log(marker2._lngLat.lat, marker2._lngLat.lng);
        this.map.fitBounds(
          [
            [marker1._lngLat.lng, marker1._lngLat.lat],
            [marker2._lngLat.lng, marker2._lngLat.lat],
          ],
          { padding: 100 }
        );
        setTimeout(() => {
          this.map.resize();
        }, 500);

        window.scroll(0, 300);
      } else {
        if (
          (this.trainForm.value.from != '' &&
            !this.from_select &&
            typeof this.trainForm.value.from != 'undefined' &&
            !this.from_select) ||
          (this.trainForm.value.to != '' &&
            !this.to_select &&
            typeof this.trainForm.value.to != 'undefined' &&
            !this.to_select)
        ) {
          console.log('if');
          this.openDialog('error_search', 'Enter or select valid location');
        } else {
          console.log('else');
          this.openDialog('error_search', '');
        }
        this.search_submitted = false;
      }
    }
  }
  title = 'Chaavie.com';

  openDialog(e, msg): void {
    localStorage.removeItem('key_type');
    localStorage.setItem('key_type', e);
    this.dialogRef = this.dialog.open(PopupComponent, {
      width: e == 'success' ? '40rem' : '23rem',
      disableClose: e == 'success' || e == 'transaction' ? true : false,
      data: {
        message: msg,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  openaddressDialog(e, msg): void {
    localStorage.removeItem('key_type');
    localStorage.setItem('key_type', e);
    this.dialogRef = this.dialog.open(PopupComponent, {
      width: '44rem',
      disableClose: true,
      data: {
        message: msg,
      },
    });
    this.dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
  // openDialog(): void {
  //   const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
  //     width: '250px',
  //     data: {name: this.name, animal: this.animal},
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     this.animal = result;
  //   });
  // }

  auto_complete(e, type, flag, key) {
    this.pre_type = type;
    var code = e.keyCode ? e.keyCode : e.which;
    // if(code == 13) { //Enter keycode
    // alert('enter press');
    // }
    // console.log(code)
    // console.log(flag)
    if (e.keycode == 9) {
      this.to_flag = false;
      this.from_flag = false;
    }
    if (
      (e.target.value.length > 4 &&
        type == 'from' &&
        flag &&
        code != 9 &&
        code != 8) ||
      (this.pre_type == 'from' &&
        e.target.value.length > 2 &&
        code == 13 &&
        code != 9 &&
        code != 8)
    ) {
      this.ls.search_location(e.target.value).then((res) => {
        this.from_list = res.features;
        this.from_list.forEach((element) => {
          let arr = [];
          // console.log(element.place_name.split(','))
          // console.log(element)
          element.place = element.place_name.split(',')[0];
          element.location_name = element.place_name.split(',')[1];
          arr.push(element.text);

          for (let i = 2; i < element.place_name.split(',').length - 1; i++) {
            // console.log(arr)
            // console.log(element.context[i].text)
            if (!arr.includes(element.place_name.split(',')[i])) {
              arr.push(element.place_name.split(',')[i]);
              element.location_name =
                element.location_name + ',' + element.place_name.split(',')[i];
            }
          }
        });
        if (this.from_list.length > 0) {
          // console.log(this.from_list)
          this.from_flag = true;
        } else {
          if (code == 13) {
            this.openDialog('error_search', '');
          }
          this.from_list = [{ place_name: '' }];
          console.log('if');

          this.from_flag = false;
        }
      });
    } else if (
      (e.target.value.length > 4 &&
        type == 'to' &&
        flag &&
        code != 9 &&
        code != 8) ||
      (this.pre_type == 'to' &&
        e.target.value.length > 2 &&
        code == 13 &&
        code != 9 &&
        code != 8)
    ) {
      this.ls.search_location(e.target.value).then((res) => {
        this.to_list = res.features;
        res.features.forEach((element) => {
          let arr = [];
          element.place = element.place_name.split(',')[0];
          element.location_name = element.place_name.split(',')[1];
          arr.push(element.text);

          for (let i = 2; i < element.place_name.split(',').length - 1; i++) {
            // console.log(arr)
            // console.log(element.context[i].text)
            if (!arr.includes(element.place_name.split(',')[i])) {
              arr.push(element.place_name.split(',')[i]);
              element.location_name =
                element.location_name + ',' + element.place_name.split(',')[i];
            }
          }
        });
        // console.log(res)
        if (this.to_list.length > 0) {
          this.to_flag = true;
        } else {
          if (code == 13) {
            this.openDialog('error_search', '');
          }
          this.to_list = [{ place_name: '' }];
          this.to_flag = false;
        }
      });
    } else {
      if (type == 'from') {
        if (this.from_index == -1 || this.from_index == 0) {
          console.log('else');
          // this.from_list = []
          this.from_list = [{ place_name: '' }];
          this.from_flag = false;
        }
      } else {
        if (this.to_index == -1 || this.to_index == 0) {
          // this.to_list = []
          this.to_list = [{ place_name: '' }];
          this.to_flag = false;
        }
      }
    }

    if (code == 13) {
      console.log(this.from_index);

      if (type == 'from') {
        if (this.from_index != -1) {
          this.select_location(this.from_list[this.from_index], 'from');
        }
      } else {
        if (this.to_index != -1) {
          this.select_location(this.to_list[this.to_index], 'to');
        }
      }
    }
    if (code == 9) {
      this.to_flag = false;
      this.from_flag = false;
    }
  }

  select_location(e, type) {
    console.log(e);
    if (type == 'from') {
      this.from_index = 0;
      // this.trainForm.value.from = e.text
      this.from_location = e;
      this.from_select = true;
      setTimeout(() => {
        console.log('select');
        this.from_flag = false;
      }, 100);
    } else {
      this.to_select = true;
      this.to_index = 0;
      this.to_location = e;
      setTimeout(() => {
        console.log('select');
        this.to_flag = false;
      }, 100);
    }
  }

  from_selecting(e, flag) {
    switch (e.keyCode) {
      case 38:
        this.moveup(e, flag);
        break;
      // case 39:
      //   moveRight()
      //   break
      case 40:
        this.moveDown(e, flag);
        break;
      case 13:
        this.empty_field(e, flag);
        break;
    }
  }

  empty_field(e, flag) {
    if (flag == 'from') {
      if (
        this.trainForm.value.from == '' ||
        this.trainForm.value.from == null
      ) {
        this.openDialog('error_search', '');
      }
    } else {
      if (this.trainForm.value.to == '' || this.trainForm.value.to == null) {
        this.openDialog('error_search', '');
      }
    }
  }

  moveDown(e, flag) {
    if (flag == 'from') {
      if (this.from_flag) {
        this.select_flag = true;
        console.log(this.from_list.length);
        if (
          this.from_index >= -1 &&
          this.from_index < this.from_list.length - 1
        ) {
          this.from_index = this.from_index + 1;
          console.log(this.from_index);
        }
      } else {
        this.select_flag = false;
      }
    }
    if (flag == 'to') {
      if (this.to_flag) {
        this.select_flag = true;
        console.log(this.to_list.length);
        if (this.to_index >= -1 && this.to_index < this.to_list.length - 1) {
          this.to_index = this.to_index + 1;
          console.log(this.to_index);
        }
      } else {
        this.select_flag = false;
      }
    }

    // if (e.target.nextElementSibling != null) {
    //   console.log(e.target.nextElementSibling.childNodes)
    //   if (e.target.nextElementSibling.childNodes.length != 0) {
    //     e.target.nextElementSibling.childNodes[0].classList.add('active')
    //     e.target.nextElementSibling.childNodes[0].focus()
    //     console.log(e.target.nextElementSibling.childNodes[0])
    //   }
    // }
  }

  moveup(e, flag) {
    if (flag == 'from') {
      if (this.from_flag) {
        console.log(this.from_index);
        if (this.from_index >= 1 && this.from_index <= 5) {
          this.from_index = this.from_index - 1;
        } else if (this.from_index == 0) {
          this.from_index = this.from_index - 1;
          this.document.getElementById('from').focus();
          console.log('input focused');
        }
      }
    }
    if (flag == 'to') {
      if (this.to_flag) {
        console.log(this.to_index);
        if (this.to_index >= 1 && this.to_index <= 5) {
          this.to_index = this.to_index - 1;
        } else if (this.to_index == 0) {
          this.to_index = this.to_index - 1;
          this.document.getElementById('to').focus();
          console.log('input focused');
        }
      }
    }
    // if (e.target.nextElementSibling != null) {
    //   console.log(e.target.nextElementSibling.childNodes)
    //   if (e.target.nextElementSibling.childNodes.length != 0) {
    //     e.target.nextElementSibling.childNodes[0].classList.add('active')
    //     e.target.nextElementSibling.childNodes[0].focus()
    //     console.log(e.target.nextElementSibling.childNodes[0])
    //   }
    // }
  }

  enter_select(e) {
    console.log('enter');
    if (e.keyCode == 13) {
      this.select_location(this.from_index, 'from');
    }
  }

  append_unit() {
    if (this.cargoform.value.package_type != '') {
      this.cargoform.value.count =
        this.cargoform.value.count + this.cargoform.value.package_type;
    }
  }

  refresh_availability() {
    // this.toggleAccordian(event)

    // console.log(objDiv1, 'progress-table');
    // console.log('cargo');
    // console.log(this.loadForm.value.rows);
    this.show_payment = false;
    this.search_submitted = true;
    this.remove_blur = false;
    this.show_message = false;
    //console.log(this.cargoform.value);
    let same_state = false;
    this.check_submitted = true;
    this.volume_arr = [];

    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    this.trainForm.value.date = this.datepipe.transform(
      this.trainForm.value.date,
      'yyyy-MM-dd'
    );

    if (this.trainForm.value.date != null) {
      this.select_date = this.trainForm.value.date;
      console.log(this.select_date, 'selected date');
    }

    if (
      this.loadControl.status == 'VALID' &&
      this.trainForm.status == 'VALID'
    ) {
      if (this.from_select && this.to_select) {
        if (
          this.from_location.context[this.from_location.context.length - 2]
            .text ==
          this.to_location.context[this.to_location.context.length - 2].text
        ) {
          same_state = true;
        }
        this.show_message = false;
        const panel = this.document.getElementById('panel'); //element.nextElementSibling
        if (panel.style.maxHeight) {
          // panel.style.maxHeight = null
        } else {
          panel.style.maxHeight = 2200 + 'px';
        }

        // this.map = new mapboxgl.Map()
        // Create a default Marker and add it to the map.
        let marker1, marker2;
        this.marker_array.forEach((element) => {
          element.remove();
        });

        marker1 = new mapboxgl.Marker({ color: 'red' })
          .setLngLat(this.from_location.center)
          .addTo(this.map);
        this.marker_array.push(marker1);

        // Create a default Marker, colored black, rotated 45 degrees.
        marker2 = new mapboxgl.Marker({ color: 'green' })
          .setLngLat(this.to_location.center)
          .addTo(this.map);
        this.marker_array.push(marker2);
        // var group = new mapboxgl.featureGroup([marker1, marker2])

        // this.map.fitBounds(group.getBounds())
        // var bounds = latLngBounds(this.marker_array)

        this.map.fitBounds(
          [
            [marker1._lngLat.lng, marker1._lngLat.lat],
            [marker2._lngLat.lng, marker2._lngLat.lat],
          ],
          { padding: 100 }
        );

        window.scroll(0, 300);

        let weight_convertion, lenth_convertion;
        if (this.weight_unit == 'KG') {
          weight_convertion = 1;
        } else if (this.weight_unit == 'TONNE') {
          weight_convertion = 1000;
        }

        if (this.length_unit == 'CM') {
          lenth_convertion = 0.01;
        } else if (this.length_unit == 'INCH') {
          lenth_convertion = 0.0254;
        } else if (this.length_unit == 'FEET') {
          lenth_convertion = 0.3048;
        }
        for (let i in this.loadForm.value.rows) {
          let volume =
            parseFloat(this.loadForm.value.rows[i].h) *
            lenth_convertion *
            parseFloat(this.loadForm.value.rows[i].l) *
            lenth_convertion *
            parseFloat(this.loadForm.value.rows[i].w) *
            lenth_convertion;
          let obj = {
            volume: parseFloat(
              (volume * this.loadForm.value.rows[i].count).toFixed(2)
            ),
            weight: parseFloat(
              (
                parseInt(this.loadForm.value.rows[i].count) *
                parseFloat(this.loadForm.value.rows[i].weight) *
                weight_convertion
              ).toFixed(2)
            ),
            height: parseFloat(this.loadForm.value.rows[i].h),
            // parseFloat(
            //   (
            //     parseFloat(this.loadForm.value.rows[i].h) * lenth_convertion
            //   ).toFixed(2)
            // ),
            breadth: parseFloat(this.loadForm.value.rows[i].w),
            // parseFloat(
            //   (
            //     parseFloat(this.loadForm.value.rows[i].w) * lenth_convertion
            //   ).toFixed(2)
            // ),
            length: parseFloat(this.loadForm.value.rows[i].l),
            //  parseFloat(
            //   (
            //     parseFloat(this.loadForm.value.rows[i].l) * lenth_convertion
            //   ).toFixed(2)
            // ),
            product_weight: parseFloat(this.loadForm.value.rows[i].weight),
            //  * weight_convertion
            count: parseInt(this.loadForm.value.rows[i].count),
            package_type: this.loadForm.value.rows[i].type.value,
            product: this.loadForm.value.rows[i].content,
            color_code: this.colors[i],
            weight_unit: this.weight_unit,
            volume_unit: this.length_unit,
          };
          this.volume_arr.push(obj);
        }
        if (this.trainForm.value.date != null) {
          // console.log(this.trainForm.value.date)
          this.select_date = this.trainForm.value.date;
          console.log(this.select_date, 'selected date');
        }
        this.remove_blur = true;
        let data = {
          from: this.from_location.center,
          to: this.to_location.center,
          date: this.select_date,
          // this.trainForm.value.date != ''
          //   ? this.trainForm.value.date
          //   : this.min_date,
          type: '',
          package_type: '',
          length: 0,
          breath: 0,
          height: 0,
          volume: this.table_volume,
          count: 0,
          volume_arr: this.volume_arr,
          // load: this.table_weight,
          load:
            this.weight_unit == 'KG'
              ? this.table_weight
              : this.table_weight * 1000,
          unit: this.cargoform.value.unit,
          same_state: same_state,
        };
        this.loader = true;
        this.loader_trigger(true);
        this.ls
          .check_availability(data)
          .then((res) => {
            console.log(res);
            if (res.status == 1) {
              this.refresh_clicked = true;
              this.isActive = false;
              this.show_map = true;
              this.show_table = true;
              this.show_message = true;
              this.button_none = true;
              this.nodataavail = false;
              // let load =
              //   this.cargoform.value.unit == 'Kg'
              //     ? this.cargoform.value.load
              //     : this.cargoform.value.load * 1000;
              // let weight = load / this.cargoform.value.count;
              // let volume =
              //   ((((this.cargoform.value.length / 100) *
              //     this.cargoform.value.breath) /
              //     100) *
              //     this.cargoform.value.height) /
              //   100;

              this.availabilitylist = res.data;

              if (this.availabilitylist.length > 0) {
                this.availabilitylist.forEach((elmt) => {
                  let total_weight_percent = 0;
                  let total_volume_percent = 0;
                  elmt.weight_first = 11;
                  elmt.weight_last = 11;
                  elmt.volume_first = 11;
                  elmt.volume_last = 11;
                  elmt.available_volume = parseFloat(
                    parseFloat(elmt.available_volume).toFixed(2)
                  );
                  let formatYmd = (date) =>
                    date.toISOString().slice(0, 10).split('-').reverse()[0] +
                    ' ' +
                    month[
                      parseInt(
                        date.toISOString().slice(0, 10).split('-').reverse()[1]
                      ) - 1
                    ] +
                    ' ' +
                    date
                      .toISOString()
                      .slice(0, 10)
                      .split('-')
                      .reverse()[2]
                      .slice(-2);

                  let format_book_date = (date) =>
                    date
                      .toISOString()
                      .slice(0, 10)
                      .split('-')
                      .reverse()
                      .join('/');
                  // let format_date=formatYmd(elmt.date)
                  elmt.format_date = formatYmd(new Date(elmt.date));
                  elmt.day = days[new Date(elmt.date).getDay()];
                  // elmt.date = format_book_date(new Date(elmt.date));
                  if (
                    elmt.available_weight != null &&
                    elmt.available_volume != null &&
                    elmt.booking_status
                  ) {
                    let weight_calc = (w) => (w / elmt.total_weight) * 100;
                    let volume_calc = (v) => (v / elmt.total_volume) * 100;
                    let weight_filled =
                      elmt.total_weight - elmt.available_weight;
                    let volume_filled =
                      elmt.total_volume - elmt.available_volume;

                    (elmt.weight_progress = [
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                    ]),
                      (elmt.volume_progress = [
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                      ]);
                    // console.log(elmt.weight_progress[0].percent)
                    elmt.weight_progress[0].percent =
                      weight_calc(weight_filled);

                    if (weight_calc(weight_filled) > 0) {
                      elmt.weight_first = 0;
                    } else {
                      elmt.weight_first = 1;
                    }
                    total_weight_percent =
                      total_weight_percent + weight_calc(weight_filled);
                    console.log(total_weight_percent, 'after weight filled');
                    elmt.volume_progress[0].percent =
                      volume_calc(volume_filled);
                    total_volume_percent =
                      total_volume_percent + volume_calc(volume_filled);
                    if (volume_calc(volume_filled) > 0) {
                      elmt.volume_first = 0;
                    } else {
                      elmt.volume_first = 1;
                    }

                    var formArray = this.loadForm.get('rows') as FormArray;

                    let lenth_convertion, weight_convertion;
                    if (this.length_unit == 'CM') {
                      lenth_convertion = 0.01;
                    } else if (this.length_unit == 'INCH') {
                      lenth_convertion = 0.0254;
                    } else if (this.length_unit == 'FEET') {
                      lenth_convertion = 0.3048;
                    }

                    if (this.weight_unit == 'KG') {
                      weight_convertion = 1;
                    } else if (this.weight_unit == 'TONNE') {
                      weight_convertion = 1000;
                    }
                    for (let i in this.loadForm.value.rows) {
                      let volume =
                        parseFloat(this.loadForm.value.rows[i].h) *
                        lenth_convertion *
                        parseFloat(this.loadForm.value.rows[i].l) *
                        lenth_convertion *
                        parseFloat(this.loadForm.value.rows[i].w) *
                        lenth_convertion *
                        parseInt(this.loadForm.value.rows[i].count);

                      let total_weight =
                        parseFloat(this.loadForm.value.rows[i].weight) *
                        weight_convertion *
                        parseInt(this.loadForm.value.rows[i].count);

                      elmt.weight_progress[parseInt(i) + 1].percent =
                        weight_calc(total_weight);
                      total_weight_percent =
                        total_weight_percent + weight_calc(total_weight);
                      elmt.volume_progress[parseInt(i) + 1].percent =
                        volume_calc(volume);
                      total_volume_percent =
                        total_volume_percent + volume_calc(volume);
                    }
                    console.log(total_weight_percent, 'after form loop');

                    elmt.weight_progress[11].percent =
                      100 - total_weight_percent;
                    if (elmt.weight_progress[11].percent > 0) {
                      elmt.weight_last = 11;
                    } else {
                      elmt.weight_last = this.loadForm.value.rows.length;
                    }
                    elmt.volume_progress[11].percent =
                      100 - total_volume_percent;
                    if (elmt.volume_progress[11].percent > 0) {
                      elmt.volume_last = 11;
                    } else {
                      elmt.volume_last = this.loadForm.value.rows.length;
                    }
                    // console.log(weight_calc(weight_filled), 'weight_cal')
                    // console.log(elmt.weight_progress[0].percent)
                  } else {
                    elmt.weight_first = 0;
                    elmt.weight_last = 0;
                    elmt.volume_first = 0;
                    elmt.volume_last = 0;
                    (elmt.weight_progress = [
                      {
                        percent: 100,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: false,
                      },
                      {
                        percent: 0,
                        show: true,
                      },
                    ]),
                      (elmt.volume_progress = [
                        {
                          percent: 100,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: false,
                        },
                        {
                          percent: 0,
                          show: true,
                        },
                      ]),
                      (elmt.count = null);
                    elmt.available_weight = null;
                    elmt.available_volume = null;
                  }
                });
                console.log(this.availabilitylist);
              }
              this.loader = false;
              this.loader_trigger(false);
            } else {
              this.show_map = false;
              if (res.show_message) {
                if (res.blur) {
                  this.remove_blur = true;
                } else {
                  this.remove_blur = false;
                }
                this.show_message = false;

                this.nodataavail = true;
                this.messsage = res.message;
                this.loader = false;
                this.loader_trigger(false);
              } else {
                // this.show_message = true
                console.log(res);
                this.openDialog('success_error', '');
                this.loader = false;
                this.loader_trigger(false);
              }
            }
            setTimeout(() => {
              let table_div = this.document.getElementById('table_details');

              if (table_div != null && this.innerWidth > 767) {
                console.log(table_div);
                console.log(table_div.offsetHeight);
                this.document.getElementById('map').style.height =
                  table_div.offsetHeight + 'px';
              }
              this.map.resize();
            }, 500);
          })
          .catch((err) => {
            console.log(err);
            this.openDialog('success_error', '');
            this.loader = false;
            this.loader_trigger(false);
          });
      } else {
        if (
          (this.trainForm.value.from != '' &&
            !this.from_select &&
            typeof this.trainForm.value.from != 'undefined' &&
            !this.from_select) ||
          (this.trainForm.value.to != '' &&
            !this.to_select &&
            typeof this.trainForm.value.to != 'undefined' &&
            !this.to_select)
        ) {
          console.log('if');
          this.openDialog('error_search', 'Enter or select valid location');
        } else {
          console.log('else');
          this.openDialog('error_search', '');
        }
        this.search_submitted = false;
      }
    } else {
      console.log('form invalid');
    }
  }

  // scroll_map(id) {
  //   let el = document.getElementById(id).offsetTop
  //   let header = document.getElementById('header').offsetHeight
  //   let top = el - header - 60
  //   window.scroll(0, top)
  //   // el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  // }

  scroll(id) {
    let el = document.getElementById(id).offsetTop;
    let header = document.getElementById('header').offsetHeight;
    let top = el - header;
    window.scroll(0, top);
    // el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  }

  close_pop() {
    this.nodataavail = false;
  }
  booking(e) {
    if (this.show_message) {
      if (this.button_none) {
        let token = this.token.getToken();
        if (token == null) {
          this.openDialog('login', '');
        } else {
          this.show_payment = true;
          this.price_obj = e;
          this.price_obj.travel_charge =
            Math.ceil(this.price_obj.travel_charge / 10) * 10;
          this.price_obj.advance = Math.ceil(this.price_obj.advance / 10) * 10;

          console.log(this.price_obj);
        }
      }
    }

    setTimeout(() => {
      this.scroll('price_section');
    }, 500);

    // window.scroll(0, 1300)
  }
  //   map.on('load', function () {
  //     this.map.addSource("route", {
  //         "type": "geojson",
  //         "data": {
  //             "type": "Feature",
  //             "properties": {},
  //             "geometry": {
  //                 "type": "LineString",
  //                 "coordinates": [
  //                  [-155.088899,19.722942],[-155.08565,19.72472],[-155.084661,19.723701],[-155.083569,19.723139],[-155.079557,19.722262],[-155.074227,19.721938],[-155.069939,19.722545],[-155.070061,19.721225],[-155.07007,19.711726]
  //                 ]
  //             }
  //         }
  //     });

  //     this.map.addLayer({
  //         "id": "route",
  //         "type": "line",
  //         "source": "route",
  //         "layout": {
  //             "line-join": "round",
  //             "line-cap": "round"
  //         },
  //         "paint": {
  //             "line-color": "#888",
  //             "line-width": 8
  //         }
  //     });
  // });
  // function() {
  //   this.directions.setOrigin(start);
  //   this.directions.addWaypoint(0, [-0.07571203, 51.51424049]);
  //   this.directions.addWaypoint(1, [-0.12416858, 51.50779757]);
  //   this.directionsthis.directions.setDestination(end);
  // }
  hide_cms(e) {
    if (e.target.value != '') {
      // e.target.parentElement.classList.remove('input')
      console.log(e.target.parentElement);
      console.log(e);
    } else {
      // e.target.parentElement.classList.add('input')
    }
  }

  isNumberKey(txt, evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46) {
      //Check if the text already contains the . character
      if (txt.value.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    }
    return true;
  }

  // showResults(event) {
  //   console.log('this is not getting called');
  //   if (event) {
  //     this.login_flag = event;
  //     // this.showResults = false;
  //   }
  // }

  confirm_pay() {
    this.openDialog('transaction', '');
  }

  update_transaction() {
    let user = this.token.getUser();
    let id = localStorage.getItem('booking_id');
    let tran = localStorage.getItem('transaction_id');
    let data = {
      booking_id: id,
      user_id: user.id,
      transaction_id: tran,
      name: user.name,
      email: user.email,
    };
    this.ls.update_transaction(data).then((res) => {
      console.log(res);
      if (res.status) {
        // location.reload()
        localStorage.setItem('order_id', res.order_id);
        this.openaddressDialog('addressform', '');
      }
    });
  }
  swap_location() {
    console.log('swap');
    console.log('before');
    console.log(this.from_location);
    console.log(this.to_location);
    let from = this.from_location;
    let to = this.to_location;
    let from_select = this.from_select;
    let to_select = this.to_select;
    this.to_location = from;
    this.from_location = to;
    this.to_flag = false;
    this.from_flag = false;
    this.from_select = to_select;
    this.to_select = from_select;
    console.log('after');
    console.log(this.from_location);
    console.log(this.to_location);
  }
  convert_decimal(e) {
    console.log('decimal');
    console.log(e);
    if (e.keyCode == 110 || e.keyCode == 190) {
      // console.log([...e.target.value])
      // console.log([...e.target.value].filter((l) => l === '.'))
      // console.log([...e.target.value].filter((l) => l === '.').length)
      if ([...e.target.value].filter((l) => l === '.').length >= 1) {
        e.preventDefault();
        return false;
        // e.target.value = e.target.value.slice(0, -1)
        // ;[...e.target.value].pop()
      }
    }
  }
}
