import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormGroupDirective,
  NgForm,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core/error/error-options'
export interface train {
  from: string
  to: string
  date: string
}
export interface corgo {
  type: string
  size: number
  count: number
  load: number
}
export interface DialogData {
  animal: string
  name: string
  width: string
  overflow: string
  button_type: any
}
export const environment = {
  mapbox: {
    accessToken:
      'pk.eyJ1IjoiY2hhYXZpZTR1YXQiLCJhIjoiY2t3b3hnZHVoMDVsejJvcWhvd3FqazdhdiJ9.JhZaa6xbXHJJfB6D4rAhvQ',
  },
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched))
  }
}
