<div id="login" class="p-3" *ngIf="button_type == 'login'">
  <h2 class="text-center mb-4">Login</h2>

  <div class="radio_cta mb-4 text-center">
    <mat-radio-group
      aria-label="Select an option"
      (change)="select_form($event.value)"
      [(ngModel)]="form_type"
    >
      <mat-radio-button
        checked
        value="Password"
        [ngStyle]="{ 'margin-right': '.8rem' }"
      >
        Password
      </mat-radio-button>
      <mat-radio-button value="OTP">Login with OTP</mat-radio-button>
    </mat-radio-group>
  </div>
  <form
    action=""
    [formGroup]="loginForm"
    *ngIf="form_type == 'Password'"
    class="loginform mt-3"
  >
    <div class="error_message">
      <span class="error" *ngIf="show_password_error">
        {{ passwordmessage }}
      </span>
    </div>

    <div class="Username d-grid mb-3">
      <label>Registered email ID</label>
      <mat-form-field appearance="fill">
        <!-- <mat-label>User name</mat-label> -->
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input
          matInput
          formControlName="email"
          placeholder=""
          type="text"
          class="email_input"
          (focus)="show_password_error = false"
        />
        <mat-error
          class=""
          *ngIf="loginControl.email.errors?.required && submit_login"
        >
          Email address is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="loginControl.email.errors?.pattern && submit_login"
        >
          Invalid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div class="password d-grid">
      <label>Password</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>lock_open</mat-icon>
        </span>
        <input
          matInput
          formControlName="password"
          placeholder=""
          [type]="pass_visiblity == 'visibility' ? 'text' : 'password'"
          [maxlength]="15"
          (focus)="show_password_error = false"
        />
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'password')">
            {{ pass_visiblity }}
          </mat-icon>
        </span>
        <mat-error
          class=""
          *ngIf="loginControl.password.errors?.required && submit_login"
        >
          Password is required
        </mat-error>

        <!-- <mat-hint align="end" class="forgot">
          <strong>Forgot Password?</strong>
        </mat-hint> -->
      </mat-form-field>
      <div class="d-flex justify-content-end forgot">
        <a (click)="openDialog('forgotpassword')">Forgot Password?</a>
      </div>
    </div>
    <button mat-stroked-button class="mt-3" (click)="login()">Login</button>
    <div class="text-center mt-3">
      <p class="m-0">
        New here?
        <a class="text-center" (click)="openDialog('register')">Register</a>
      </p>
    </div>
  </form>

  <form
    action=""
    [formGroup]="otpLoginForm"
    *ngIf="form_type == 'OTP'"
    class="loginform mt-3"
  >
    <div class="error_message">
      <span class="error" *ngIf="show_otp_error">
        {{ otp_message }}
      </span>
    </div>

    <div class="Username d-grid mb-3">
      <label>Registered email ID</label>
      <mat-form-field appearance="fill">
        <!-- <mat-label>User name</mat-label> -->
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="email"
          type="email"
          class="email_input"
          [readonly]="otp_request ? 'true' : 'false'"
          (focus)="show_otp_error = false"
        />
        <mat-error
          class=""
          *ngIf="otpLoginControl.email.errors?.required && otp_clicked"
        >
          Email address is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="otpLoginControl.email.errors?.pattern && otp_clicked"
        >
          Invalid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div class="password d-grid" *ngIf="otp_request">
      <label>OTP</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>lock_open</mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="otp"
          type="text"
          [maxlength]="6"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          (focus)="show_otp_error = false"
        />
        <mat-error
          class=""
          *ngIf="otpLoginControl.otp.errors?.required && submit_login"
        >
          OTP is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="otpLoginControl.otp.errors?.minlength && submit_login"
        >
          Enter a valid 6 digit OTP
        </mat-error>
      </mat-form-field>
      <!-- <div class="d-flex justify-content-end forgot">
        <a>Forgot Password?</a>
      </div> -->
    </div>
    <button
      mat-stroked-button
      class="mt-3"
      (click)="send_otp()"
      *ngIf="!otp_request"
    >
      Send OTP
    </button>
    <button
      type="submit"
      mat-stroked-button
      class="mt-3"
      *ngIf="otp_request"
      (click)="login()"
    >
      Login
    </button>
    <div class="text-center mt-3">
      <p class="m-0">
        New here?
        <a class="text-center" (click)="openDialog('register')">Register</a>
      </p>
    </div>
  </form>
</div>

<div id="login" class="p-3" *ngIf="button_type == 'forgotpassword'">
  <h2 class="text-center mb-3 forgot_title">Forgot password</h2>
  <div class="error_message">
    <span class="error" *ngIf="show_error">
      {{ servicemessage }}
    </span>
  </div>
  <form action="" [formGroup]="forgotForm" class="loginform mt-3">
    <div class="email d-grid mb-1">
      <label>Email</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>email</mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="email"
          type="email"
          class="email_input"
          (focus)="show_error = false"
        />
        <mat-error
          class=""
          *ngIf="forgotControl.email.errors?.required && submit_forgot"
        >
          Email address is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="forgotControl.email.errors?.pattern && submit_forgot"
        >
          Invalid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div class="text-center">
      <button mat-stroked-button class="my-3" (click)="forgot_password()">
        Continue
      </button>
      <!-- <p class="m-0">
        Remember your password ?
        <a class="text-center" (click)="openDialog('login')">Login</a>
      </p> -->
    </div>
  </form>
</div>

<div id="changepassword" class="p-3" *ngIf="button_type == 'changepassword'">
  <div class="icon">
    <mat-icon (click)="popup_close()">cancel</mat-icon>
  </div>
  <h2 class="text-center mb-3 forgot_title">Reset password</h2>
  <div class="error_message">
    <span class="error" *ngIf="show_error">
      {{ servicemessage }}
    </span>
  </div>
  <form action="" [formGroup]="changeForm" class="loginform mt-3">
    <div class="email d-grid mb-1">
      <label>New password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'password')">
            {{ pass_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="new_password"
          [type]="pass_visiblity == 'visibility' ? 'text' : 'password'"
          [maxlength]="15"
        />
        <mat-error
          class=""
          *ngIf="changeControl.new_password.errors?.required && submit_reset"
        >
          New password is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="email d-grid mb-1">
      <label>Confirm password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'confirm')">
            {{ confirm_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="confirm_password"
          [type]="confirm_visiblity == 'visibility' ? 'text' : 'password'"
          [maxlength]="15"
        />
        <mat-error
          class=""
          *ngIf="
            changeControl.confirm_password.errors?.required && submit_reset
          "
        >
          Confirm password is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="
            changeControl.confirm_password.errors?.notEquivalent && submit_reset
          "
        >
          Confirm password doesn't match new password
        </mat-error>
      </mat-form-field>
    </div>
    <div class="text-center reset_cta mt-3">
      <button mat-stroked-button class="mb-3" (click)="reset_password()">
        Reset password
      </button>
    </div>
  </form>
</div>

<div id="register" class="px-3" *ngIf="button_type == 'register'">
  <h2 class="text-center">Register</h2>
  <div class="error_message">
    <span class="error" *ngIf="show_error">
      {{ servicemessage }}
    </span>
  </div>

  <form [formGroup]="registerForm" action="" class="registerform mt-3">
    <div class="Username d-grid mb-1">
      <label>Name</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>perm_identity</mat-icon>
        </span>
        <input
          matInput
          formControlName="name"
          placeholder=""
          type="text"
          (keypress)="ValidateAlpha($event)"
          ValidateAlpha
        />
        <mat-error
          class=""
          *ngIf="regitserControl.name.errors?.required && submit_register"
        >
          Name is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="regitserControl.name.errors?.pattern && submit_register"
        >
          Name must contain only alphabets
        </mat-error>
      </mat-form-field>
    </div>
    <div class="email d-grid mb-1">
      <label>Email</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>email</mat-icon>
        </span>
        <input
          matInput
          formControlName="email"
          placeholder=""
          type="email"
          class="email_input"
          (focus)="show_error = false"
        />
        <mat-error
          class=""
          *ngIf="regitserControl.email.errors?.required && submit_register"
        >
          Email address is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="regitserControl.email.errors?.pattern && submit_register"
        >
          Invalid email address
        </mat-error>
      </mat-form-field>
    </div>
    <div class="phone d-grid mb-1">
      <label>Phone number</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>phone_in_talk</mat-icon>
        </span>

        <input
          matInput
          formControlName="phone"
          placeholder=""
          [maxlength]="10"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
        <mat-error
          class=""
          *ngIf="regitserControl.phone.errors?.required && submit_register"
        >
          Phone number is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="regitserControl.phone.errors?.minlength && submit_register"
        >
          Please enter a valid phone number
        </mat-error>
      </mat-form-field>
    </div>
    <div
      class="align-items-end {{
        innerWidth <= 420 ? 'd-grid' : 'd-flex'
      }} address"
    >
      <div
        class="address d-grid mb-1"
        [ngStyle]="{ width: innerWidth <= 420 ? '100%' : '43%' }"
      >
        <label>Address</label>
        <mat-form-field appearance="fill">
          <span matPrefix>
            <mat-icon>location_on</mat-icon>
          </span>
          <input
            matInput
            formControlName="address"
            placeholder=""
            type="text"
          />
          <mat-error
            class=""
            *ngIf="regitserControl.address.errors?.required && submit_register"
          >
            Address is required
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="pin mb-1"
        [ngStyle]="{
          width: innerWidth <= 420 ? '100%' : '26%',
          margin: innerWidth <= 420 ? '0 0 0 0' : '0 0 0 31%'
        }"
      >
        <label>Pincode</label>
        <mat-form-field appearance="fill" id="pincode">
          <input
            matInput
            formControlName="pin"
            placeholder=""
            type="text"
            [maxlength]="6"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
          />
          <mat-error
            class=""
            *ngIf="regitserControl.pin.errors?.required && submit_register"
          >
            Pincode is required
          </mat-error>
          <mat-error
            class=""
            *ngIf="regitserControl.pin.errors?.minlength && submit_register"
          >
            Enter a valid 6 digit Pincode
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="gst d-grid mb-1">
      <label>GST number</label>
      <mat-form-field appearance="fill">
        <span matPrefix>
          <mat-icon>developer_board</mat-icon>
        </span>
        <input
          matInput
          formControlName="gst"
          placeholder=""
          type="text"
          [maxlength]="15"
          onkeypress="return (event.charCode > 96 && event.charCode < 123) || (event.charCode > 64 && event.charCode < 91) || (event.charCode >= 48 && event.charCode <= 57) || event.charCode <= 31"
        />
        <mat-error
          class=""
          *ngIf="regitserControl.gst.errors?.required && submit_register"
        >
          GST number is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="regitserControl.gst.errors?.minlength && submit_register"
        >
          Enter a valid 15 digit GST number
        </mat-error>
      </mat-form-field>
    </div>

    <div class="Username d-grid mb-1">
      <label>Password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'password')">
            {{ pass_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="password"
          [type]="pass_visiblity == 'visibility' ? 'text' : 'password'"
          [maxlength]="15"
          (focus)="show_error = false"
        />
        <mat-error
          class=""
          *ngIf="regitserControl.password.errors?.required && submit_register"
        >
          Password is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="Username d-grid mb-1">
      <label>Confirm password</label>
      <mat-form-field appearance="fill">
        <span matSuffix>
          <mat-icon class="pointer" (click)="show_pass($event, 'confirm')">
            {{ confirm_visiblity }}
          </mat-icon>
        </span>
        <input
          matInput
          placeholder=""
          formControlName="confirm_password"
          [type]="confirm_visiblity == 'visibility' ? 'text' : 'password'"
          [maxlength]="15"
        />
        <mat-error
          class=""
          *ngIf="
            regitserControl.confirm_password.errors?.required && submit_register
          "
        >
          Confirm password is required
        </mat-error>
        <mat-error
          class=""
          *ngIf="
            regitserControl.confirm_password.errors?.notEquivalent &&
            submit_register
          "
        >
          Confirm password doesn't match password
        </mat-error>
      </mat-form-field>
    </div>
    <button mat-stroked-button class="mt-3" (click)="register_user()">
      Register
    </button>
    <div class="text-center mt-3">
      <p class="m-0">
        Already have an account?
        <a class="text-center" (click)="openDialog('login')">Login</a>
      </p>
    </div>
  </form>
</div>

<div id="successpopup" *ngIf="button_type == 'success'">
  <!-- <div class="icon">
    <mat-icon (click)="popup_close()">cancel</mat-icon>
  </div> -->
  <div class="text-center" *ngIf="success_res">
    <p>Thank you for placing your order {{ order_number }} with us.</p>
    <p>Our team will get back to you at the earliest for further processing.</p>
  </div>
  <div class="text-center" *ngIf="!success_res">
    <p>{{ success_response }}</p>
  </div>
  <div class="text-center">
    <button mat-stroked-button (click)="popup_close()">Ok</button>
  </div>
</div>

<div id="successpopup" *ngIf="button_type == 'success_error'">
  <div class="text-center">
    <p>{{ error_message }}</p>
  </div>
  <div class="text-center">
    <button mat-stroked-button (click)="popup_close()">Ok</button>
  </div>
</div>

<div id="successpopup" *ngIf="button_type == 'error_search'">
  <div class="text-center">
    <p>{{ error_message1 }}</p>
  </div>
  <div class="text-center">
    <button mat-stroked-button (click)="this.dialogRef.close()">Ok</button>
  </div>
</div>

<div id="transactionpopup" *ngIf="button_type == 'transaction'">
  <div class="icon">
    <mat-icon (click)="this.dialogRef.close()">cancel</mat-icon>
  </div>
  <div class="text-center">
    <h4>Enter Transcation ID</h4>
    <form class="transaction" [formGroup]="trans_Form">
      <mat-form-field class="transaction" appearance="fill">
        <input
          type="text"
          [(ngModel)]="transaction_id"
          [ngModelOptions]="{ standalone: true }"
          matInput
          formControlName="trans_id"
        />
        <mat-error
          class=""
          *ngIf="
            transactionControl.trans_id.errors?.required && submit_transaction
          "
        >
          This field is required
        </mat-error>
      </mat-form-field>
      <div class="text-center mt-3">
        <button mat-stroked-button (click)="update_payment()">Ok</button>
      </div>
    </form>
  </div>
</div>

<div id="addresspopup" class="px-1" *ngIf="button_type == 'addressform'">
  <!-- <div class="icon">
    <mat-icon (click)="this.dialogRef.close()">cancel</mat-icon>
  </div> -->
  <div class="text-center px-4">
    <form
      class="addresspopup"
      [formGroup]="address_form"
      (submit)="update_address()"
    >
      <div class="row">
        <div class="col-md-6 col-order1">
          <div class="htext">
            <h2 class="h2-text">Pickup Details</h2>
          </div>
        </div>
        <div class="col-md-6 col-order3">
          <div class="htext">
            <h2 class="h2-text">Drop Details</h2>
          </div>
        </div>
        <div class="col-md-6 mat-col col-order2">
          <div class="inner-col-one pe-md-4">
            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>business</mat-icon>
              <input
                type="text"
                placeholder="Company name"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
                matInput
                formControlName="company_name"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.company_name.errors?.required &&
                  address_submitted
                "
              >
                Company name is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Address line 1"
                matInput
                formControlName="address_one"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.address_one.errors?.required &&
                  address_submitted
                "
              >
                Address line 1 is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Address line 2"
                matInput
                formControlName="address_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.address_two.errors?.required &&
                  address_submitted
                "
              >
                Address line 2 is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="City or town"
                matInput
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
                formControlName="city"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.city.errors?.required && address_submitted
                "
              >
                City or town is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="addressControl.city.errors?.pattern && address_submitted"
              >
                City or town should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Pincode"
                matInput
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 47 && event.charCode <= 57"
                formControlName="pin1"
                maxlength="6"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.pin1.errors?.required && address_submitted
                "
              >
                Pincode is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="addressControl.pin1.errors?.pattern && address_submitted"
              >
                Pincode should contain only numbers.
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.pin1.errors?.minlength && address_submitted
                "
              >
                Please enter a valid pincode.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="District"
                matInput
                formControlName="district"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.district.errors?.required && address_submitted
                "
              >
                District is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.district.errors?.pattern && address_submitted
                "
              >
                District should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="State"
                matInput
                formControlName="state"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.state.errors?.required && address_submitted
                "
              >
                State is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.state.errors?.pattern && address_submitted
                "
              >
                State should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>person</mat-icon>
              <input
                type="text"
                placeholder="Contact person"
                matInput
                formControlName="contact_person"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_person.errors?.required &&
                  address_submitted
                "
              >
                Contact person is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_person.errors?.pattern &&
                  address_submitted
                "
              >
                Contact person should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>phone</mat-icon>
              <input
                type="text"
                placeholder="Contact number"
                matInput
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 47 && event.charCode <= 57"
                formControlName="contact_number"
                maxlength="10"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number.errors?.required &&
                  address_submitted
                "
              >
                Contact number is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number.errors?.pattern &&
                  address_submitted
                "
              >
                Contact number should contain only numbers.
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number.errors?.minlength &&
                  address_submitted
                "
              >
                Contact number is invalid.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-md-6 mat-col col-order4">
          <div class="inner-col-two ps-md-4">
            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>business</mat-icon>
              <input
                type="text"
                placeholder="Company name"
                matInput
                formControlName="company_name_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.company_name_two.errors?.required &&
                  address_submitted
                "
              >
                Company name is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Address line 1"
                matInput
                formControlName="address_one_one"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.address_one_one.errors?.required &&
                  address_submitted
                "
              >
                Address line 1 is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Address line 2"
                matInput
                formControlName="address_two_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.address_two_two.errors?.required &&
                  address_submitted
                "
              >
                Address line 2 is required
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="City or town"
                matInput
                formControlName="city_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.city_two.errors?.required && address_submitted
                "
              >
                City or town is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.city_two.errors?.pattern && address_submitted
                "
              >
                City or town should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="Pincode"
                matInput
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 47 && event.charCode <= 57"
                formControlName="pin2"
                maxlength="6"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.pin2.errors?.required && address_submitted
                "
              >
                Pincode is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="addressControl.pin2.errors?.pattern && address_submitted"
              >
                Pincode should contain only numbers.
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.pin2.errors?.minlength && address_submitted
                "
              >
                Please enter a valid pincode.
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="District"
                matInput
                formControlName="district_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.district_two.errors?.required &&
                  address_submitted
                "
              >
                District is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.district_two.errors?.pattern &&
                  address_submitted
                "
              >
                District should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>location_on</mat-icon>
              <input
                type="text"
                placeholder="State"
                matInput
                formControlName="state_two"
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.state_two.errors?.required && address_submitted
                "
              >
                State is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.state_two.errors?.pattern && address_submitted
                "
              >
                State should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>person</mat-icon>
              <input
                type="text"
                placeholder="Contact person"
                matInput
                onkeypress="return (event.target.value.length==0)?event.charCode != 32:true"
                onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)"
                formControlName="contact_person_two"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_person_two.errors?.required &&
                  address_submitted
                "
              >
                Contact person is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_person_two.errors?.pattern &&
                  address_submitted
                "
              >
                Contact person should contain only alphabets
              </mat-error>
            </mat-form-field>

            <mat-form-field class="transaction" appearance="fill">
              <mat-icon matPrefix>phone</mat-icon>
              <input
                type="text"
                placeholder="Contact number"
                matInput
                formControlName="contact_number_two"
                onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 47 && event.charCode <= 57"
                maxlength="10"
              />
              <!-- [ngModelOptions]="{ standalone: true }" -->
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number_two.errors?.required &&
                  address_submitted
                "
              >
                Contact number is required
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number_two.errors?.pattern &&
                  address_submitted
                "
              >
                Contact number should contain only numbers.
              </mat-error>
              <mat-error
                class=""
                *ngIf="
                  addressControl.contact_number_two.errors?.minlength &&
                  address_submitted
                "
              >
                Contact number is invalid.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="mat-button col-order5">
          <div class="text-center mt-3">
            <button
              mat-stroked-button
              type="button"
              (click)="reset_address_form()"
            >
              Reset
            </button>
          </div>
          <div class="text-center mt-3">
            <button mat-stroked-button type="submit">Submit</button>
          </div>
        </div>
      </div>

      <!-- <div class="text-center mt-3">
        <button mat-stroked-button (click)="update_payment()">Ok</button>
      </div> -->
    </form>
  </div>
</div>
