import {
  Component,
  HostListener,
  Inject,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { title } from 'process';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { Observable, from, Subject, of } from 'rxjs';
import {
  MyErrorStateMatcher,
  DialogData,
  environment,
} from './trainfrom.model';
import * as mapboxgl from 'mapbox-gl';
// import { MatAutocompleteTrigger } from '@angular/material'
import { TokenService } from './service/token.service';
import { UserService } from './service/user.service';
// import PopupComponent from './popup/popup.component'
import {
  FormGroup,
  FormBuilder,
  FormGroupDirective,
  NgForm,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PopupComponent } from './popup/popup.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LogicService } from './service/logic.service';
import { DateAdapter } from '@angular/material/core';
// import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {}
