<app-header></app-header>
<section id="video-page details_section">
  <div class="container">
    <div class="accord px-lg-2">
      <div class="video_lib_div">
        <div class="video_lib_outer">
          <div class="hr_line">
            <div class="video_lib">
              <div class="video_take">
                <img src="/assets/images/Video_take.svg" alt="" />
              </div>
              <div class="lib_text">
                <p>Library</p>
              </div>
            </div>
          </div>
          <div class="video_div" *ngFor="let item of videoSources; index as i">
            <div class="row video_row">
              <div class="col-md-4 col-sm-12">
                <div class="video_text">
                  <div class="video_text_title">
                    <p>{{ item[0].title }}</p>
                  </div>
                  <div class="video_text_desc">
                    <p>{{ item[0].dec }}</p>
                  </div>
                </div>
              </div>
              <div class="col-md-8 col-sm-12 video_col">
                <div class="video_div">
                  <!-- <iframe width="666" height="315" src="https://www.youtube.com/embed/RK1K2bCg4J8"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen></iframe> -->
                  <!-- <video  id="my_video_1_{{i}}"  width="100%" height="280" controls="controls autoplay"  >
                                            <source src="{{data.blog_path }}"  />
                                          </video>
                                          <button (click)="playPause(i)" *ngIf="!data.video_played" >
                                          <img src="/assets/images/logos_youtube-icon.svg" alt=""/>
                                          </button>  -->

                  <!-- <video
                    id="player"
                    playsinline
                    controls
                    data-poster="/path/to/poster.jpg"
                  >
                    <source src="{{ item[0].src }}" type="video/mp4" />

                    <track
                      kind="captions"
                      label="English captions"
                      src="{{ item.src }}/to/video.mp4"
                      srclang="en"
                      default
                    />
                  </video> -->
                  <plyr
                    class="plyr-custom"
                    style="display: block; width: 640px"
                    plyrTitle="Video 1"
                    [plyrSources]="item"
                    (plyrInit)="player = $event"
                    (plyrPlay)="played($event)"
                  ></plyr>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
