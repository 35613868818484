import {
  Component,
  HostListener,
  Inject,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { TokenService } from '../service/token.service';
import { LogicService } from '../service/logic.service';
import { PlyrComponent } from 'ngx-plyr';
@Component({
  selector: 'app-video-page',
  templateUrl: './video-page.component.html',
  styleUrls: ['./video-page.component.scss'],
})
export class VideoPageComponent implements OnInit {
  videolist: any = [];
  blog_title: any = [];
  blog_description: any = [];
  isButtonVisible: boolean = true;
  @ViewChild(PlyrComponent)
  plyr: PlyrComponent;
  player: Plyr;
  videoSources1: Plyr.Source[] = [
    {
      src: 'https://uat.chaavie.com:5051/videos/1658929154442-Chaavie-file_example_MP4_480_1_5MG.mp4',
      type: 'video/mp4',
    },
  ];
  newvideolist: any = [];
  constructor(private token: TokenService, private ls: LogicService) {}

  ngOnInit(): void {
    this.getall_video();
    console.log('Video page angular');
    console.log(this.videolist, 'uiyio');
    // this.videolist.forEach((ele) => {
    //   this.newvideolist.push([{ src: ele.blog_path, type: 'video/mp4' }]);
    // });
    // console.log(this.newvideolist, 'uoyieur');
  }
  videoSources: Plyr.Source[] = this.newvideolist;

  played(event: Plyr.PlyrEvent) {
    console.log('played', event);
  }

  play(): void {
    this.player.play(); // or this.plyr.player.play()
  }

  stop(): void {
    this.player.stop(); // or this.plyr.player.play()
  }

  // playPause(e) {
  //   console.log(`my_video_1_${e}`, 'Video');
  //   // e.stopPropogation()
  //   var myVideo: any = document.getElementById(`my_video_1_${e}`);
  //   if (myVideo.paused) myVideo.play();
  //   else myVideo.pause();
  //   if (this.videolist[e].video_played == true) {
  //     this.videolist[e].video_played = false;
  //     console.log('invisible');
  //   } else {
  //     this.videolist[e].video_played = true;
  //     console.log('visible');
  //   }
  //   // this.isButtonVisible=false
  // }

  getall_video() {
    this.ls
      .get_video()
      .then((res) => {
        if (res.status) {
          console.log(res.data, 'data');
          this.videolist = res.data;
          this.videolist.forEach((ele) => {
            this.newvideolist.push([
              {
                src: ele.blog_path,
                type: 'video/mp4',
                title: ele.blog_title,
                dec: ele.blog_description,
              },
            ]);
          });

          console.log(this.videoSources, 'uoyieur');
          if (this.videolist.length < 0) {
            for (let i of this.videolist) {
              i.video_played = false;
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
