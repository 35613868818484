<app-header></app-header>
<section id="banner">
  <div class="container">
    <div class="row mx-1">
      <div class="col-md-12 bg-image">
        <div class="title_sec ms-lg-5 ms-md-5 ms-4">
          <div class="title mt-3 mt-lg-4">
            <h1>Ship hassle free from your door step.</h1>
          </div>
          <div class="text">
            <p>
              Faster, safer & cost efficient solutions for your business at your
              finger tips.
            </p>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-6">
          <div class="banner_image">
            <img
              src="/assets/images/banner.svg"
              class="mt-lg-4 object-fit"
              alt="banner-image"
            />
          </div>
        </div> -->
    </div>
  </div>
</section>

<section id="details_section" class="my-lg-4 my-md-3">
  <div class="container">
    <div class="accord px-lg-2">
      <div class="container">
        <div class="row bg_color_blue px-lg-2 pt-lg-4 mt-3" id="bg_color_blue">
          <div class="col-md-4 col-lg-3 col-12 my-2">
            <div class="my-3 my-lg-0">
              <form id="search" [formGroup]="trainForm" (keydown.enter)="$event.preventDefault()"
                class="d-grid justify-content-between align-items-center py-4 px-4 py-lg-4 px-lg-4 py-md-2 px-md-2">
                <div class="input_wrap mt-3 mb-3">
                  <label>Pickup location</label>
                  <input class="" type="text" name="From" formControlName="from" [readonly]="nodataavail" id="from"
                    [(ngModel)]="from_location.place_name" autocomplete="off" (input)="
                      auto_complete($event, 'from', true, 'input');
                      this.from_select = false
                    " placeholder="Pickup location" (keydown)="
                      from_selecting($event, 'from');
                      auto_complete($event, 'from', true, 'key')
                    " />
                  <!-- from_selecting($event) -->
                  <span class="error" *ngIf="errorControl.from.errors && search_submitted">
                    Please enter your source location
                  </span>

                  <ul class="searchlist" [ngStyle]="{
                      top: '4.6rem'
                    }" *ngIf="from_flag">
                    <li *ngFor="let i of from_list; index as j" value="{{ i }}" tabindex="{{ j }}"
                      focus="{{ from_index == j ? 'true' : 'false' }}" class="{{ from_index == j ? 'active' : '' }}"
                      (click)="select_location(i, 'from')" (keypress)="enter_select($event, 'from')">
                      <!-- if(event.keyCode==13) return select_location(from_list[from_index], 'from') -->
                      <ul>
                        <li>{{ i.place }}</li>
                        <li>{{ i.location_name }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="arrow_div" (click)="!nodataavail ? swap_location() : ''">
                  <img src="/assets/images/Vector.svg" />
                </div>
                <div class="input_wrap mt-2 mt-md-2 mt-lg-3 mb-3">
                  <label>Drop location</label>
                  <input class="" type="text" (input)="
                      auto_complete($event, 'to', true, 'input');
                      this.to_select = false
                    " [readonly]="nodataavail" name="to" id="to" formControlName="to"
                    [(ngModel)]="to_location.place_name" placeholder="Drop location" autocomplete="off" (keydown)="
                      from_selecting($event, 'to');
                      auto_complete($event, 'to', true, 'key')
                    " />
                  <span class="error" *ngIf="errorControl.to.errors && search_submitted">
                    Please enter your destination location
                  </span>
                  <ul class="searchlist" [ngStyle]="{
                      top: '4.6rem'
                    }" *ngIf="to_flag">
                    <li *ngFor="let i of to_list; index as j" value="{{ i }}" (click)="select_location(i, 'to')"
                      tabindex="{{ j }}" focus="{{ to_index == j ? 'true' : 'false' }}"
                      class="{{ to_index == j ? 'active' : '' }}" (keypress)="enter_select($event, 'to')">
                      <ul>
                        <li>{{ i.place }}</li>
                        <li>{{ i.location_name }}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div class="input_wrap mt-2 mt-md-3 mt-lg-3 mb-3">
                  <label>Pick up date</label>
                  <mat-form-field id="pickaday" appearance="fill">
                    <input id="datepicker" class=" {{ date_place ? 'date' : '' }} date-polyfill"
                      (change)="select($event)" matInput [matDatepicker]="datepicker" [min]="this.min_date"
                      autocomplete="off" name="date" datePickerFormat="DD/MM/YYYY" readonly="true"
                      (click)="!nodataavail ? datepicker.open() : ''" (focus)="!nodataavail ? datepicker.open() : ''"
                      (keydown)="!nodataavail ? datepicker.open() : ''" formControlName="date"
                      placeholder="Pick up date" />
                    <mat-icon matSuffix>date_range</mat-icon>
                    <!-- <mat-datepicker-toggle
                        matSuffix
                        [for]="datepicker"
                      ></mat-datepicker-toggle> -->
                    <mat-datepicker #datepicker></mat-datepicker>
                  </mat-form-field>
                  <span class="error" *ngIf="errorControl.date.errors && search_submitted">
                    Date should not be less than {{ this.min_date }}
                  </span>
                </div>
                <!-- <div class="truck_detail">
                  <span>Book shipment</span>
                  <div class="toggle_button truck_toggle">
                    <mat-slide-toggle [(ngModel)]="!istruck_details"></mat-slide-toggle>
                  </div>
                  <span>Book a truck</span>
                </div> -->
                <!-- <button
                    type="submit"
                    mat-stroked-button
                    class="close"
                    (click)="toggleAccordian($event)"
                    [ngStyle]="{
                      width: show_map || innerWidth <= 767 ? '33%' : '',
                      margin: show_map || innerWidth <= 767 ? 'auto' : ''
                    }"
                  >
                    Search
                  </button> -->
              </form>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 col-12 my-2 my-md-4 my-lg-2 ps-lg-0 ps-md-0">
            <div id="load_details" class="px-3 py-4 py-md-3 py-lg-4" *ngIf="!istruck_details">
              <div class="row top_row mx-1">
                <div class="col-md-4 col-lg-6 p-0">
                  <h3>Shipment details</h3>
                </div>
                <div class="col-md-8 col-lg-6 p-0">
                  <div class="row mb-2">
                    <div class="measure_volume col-6">
                      <!-- <mat-radio-group
                          aria-label="Select an option"
                          class="pe-lg-3"
                        >
                          <mat-radio-button [disableRipple]="true" value="CM"
                            >CM</mat-radio-button
                          >
                          <mat-radio-button [disableRipple]="true" value="INCH"
                            >INCH</mat-radio-button
                          >
                          <mat-radio-button [disableRipple]="true" value="FEET"
                            >FEET</mat-radio-button
                          >
                        </mat-radio-group> -->
                      <mat-button-toggle-group [disabled]="nodataavail" name="measure_volume"
                        aria-label="measure_volume" [(ngModel)]="length_unit" (change)="calculation()">
                        <mat-button-toggle checked value="CM">CM</mat-button-toggle>
                        <mat-button-toggle value="INCH">INCH</mat-button-toggle>
                        <mat-button-toggle value="FEET">FEET</mat-button-toggle>
                      </mat-button-toggle-group>

                      <div>
                        <p>Toggle to choose another unit</p>
                      </div>
                    </div>
                    <div class="measure_weight col-6">
                      <!-- <mat-radio-group
                          aria-label="Select an option"
                          class="pe-lg-3"
                        >
                          <mat-radio-button [disableRipple]="true" value="KG"
                            >KG
                          </mat-radio-button>
                          <mat-radio-button [disableRipple]="true" value="TONNE"
                            >TONNE</mat-radio-button
                          >
                        </mat-radio-group> -->
                      <mat-button-toggle-group name="measure_volume" aria-label="measure_volume"
                        [(ngModel)]="weight_unit" [disabled]="nodataavail" (change)="calculation()">
                        <mat-button-toggle checked value="KG">KG</mat-button-toggle>
                        <mat-button-toggle value="TONNE">TONNE</mat-button-toggle>
                      </mat-button-toggle-group>
                      <div>
                        <p>Toggle to choose another unit</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message" *ngIf="nodataavail">
                <div class="empty_message_div">
                  <mat-icon (click)="close_pop()">close</mat-icon>
                  <div class="empty_message">
                    <p>{{ messsage }}</p>
                  </div>
                </div>
              </div>
              <div class="table_div noselect mt-3 mt-md-2" id="table_div">
                <form [formGroup]="loadForm">
                  <table class="">
                    <thead>
                      <th></th>
                      <th></th>
                      <th [colSpan]="3" class="dimension">Dimensions</th>
                    </thead>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Product Details</th>
                        <th>Length</th>
                        <th>Width</th>
                        <th>Height</th>
                        <th>Weight</th>
                        <th>Count</th>
                        <th>Total Load</th>
                        <th>Total Volume</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody id="table_body">
                      <tr *ngFor="
                          let row of loadForm.get('rows')?.controls;
                          let index = index
                        ">
                        <td>
                          <mat-form-field appearance="none" class=" {{
                              loadControl.controls[index].controls.type
                                .errors && check_submitted
                                ? 'select_error'
                                : ''
                            }}">
                            <mat-select name="package" [formControl]="row.get('type')" [disabled]="nodataavail"
                              placeholder="Type" (selectionChange)="get_image($event.value, index)">
                              <!-- formControlName="package_type" -->
                              <mat-select-trigger>
                                <mat-icon class="me-2"><img id="package_icon" src="{{ type_arr[index].image }}" />
                                </mat-icon>
                                {{ type_arr[index].type }}
                              </mat-select-trigger>
                              <mat-option *ngFor="let package of packages; index as j" [value]="package"
                                [selected]="j == 0">
                                <mat-icon id="mat-icon"><img id="package_icon" src="{{ package.image }}" /></mat-icon>
                                {{ package.viewValue }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('content')" [readonly]="nodataavail" class=" {{
                              loadControl.controls[index].controls.content
                                .errors && check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('l')" [readonly]="nodataavail"
                            (input)="calculation()" (keydown)="convert_decimal($event)"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 46 && event.charCode <= 57"
                            class=" {{
                              loadControl.controls[index].controls.l.errors &&
                              check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                          <p>{{ unit_length }}</p>
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('w')" [readonly]="nodataavail"
                            (input)="calculation()" (keydown)="convert_decimal($event)"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 46 && event.charCode <= 57"
                            class=" {{
                              loadControl.controls[index].controls.w.errors &&
                              check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                          <p>{{ unit_length }}</p>
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('h')" [readonly]="nodataavail"
                            (input)="calculation()" (keydown)="convert_decimal($event)"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 46 && event.charCode <= 57"
                            class=" {{
                              loadControl.controls[index].controls.h.errors &&
                              check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                          <p>{{ unit_length }}</p>
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('weight')" [readonly]="nodataavail"
                            (input)="calculation()" (keydown)="convert_decimal($event)"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 46 && event.charCode <= 57"
                            class=" {{
                              loadControl.controls[index].controls.weight
                                .errors && check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                          <p>{{ unit_weight }}</p>
                        </td>
                        <td>
                          <input type="text" [formControl]="row.get('count')" [readonly]="nodataavail"
                            (input)="calculation()"
                            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode > 46 && event.charCode <= 57"
                            class=" {{
                              loadControl.controls[index].controls.count
                                .errors && check_submitted
                                ? 'input_error'
                                : ''
                            }}" />
                        </td>
                        <td>
                          <input type="text" readonly [formControl]="row.get('totalload')" />
                          <p>{{ unit_weight }}</p>
                        </td>
                        <td>
                          <input type="text" readonly [formControl]="row.get('totalvolume')" />
                          <p>m³</p>
                        </td>
                        <td>
                          <div class="d-flex justify-content-center">
                            <div class="color_circle"></div>
                            <img src="assets/images/delete.svg" (click)="!nodataavail ? onRemoveRow(index) : ''" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </form>
              </div>
              <div class="cta_div mt-1">
                <span class="error1" *ngIf="loadControl.status != 'VALID' && check_submitted">Please enter the relevant
                  details
                </span>
                <!-- <div class="text_note">
                    <p>
                      Note: Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit. Nullam laoreet pulvinar purus vel volutpat. Curabitur
                      lorem enim.
                    </p>
                  </div> -->
                <div class="d-flex justify-content-end align-items-center shipment_total">
                  <p *ngIf="total_flag">Total</p>
                  <p *ngIf="total_flag">{{ table_count }}</p>
                  <p *ngIf="total_flag">{{ table_weight }} {{ unit_weight }}</p>
                  <p *ngIf="total_flag">{{ table_volume }} m³</p>
                </div>
                <div class="d-lg-flex d-md-flex d-grid justify-content-between align-items-baseline inner_cta_div">
                  <button mat-stroked-button class="close" (click)="onAddRow()" [disabled]="nodataavail">
                    <mat-icon>add</mat-icon>
                    Add new product
                  </button>
                  <button mat-stroked-button class="close" type="submit" [disabled]="nodataavail"
                    (click)="refresh_availability()">
                    {{
                    refresh_clicked
                    ? "Refresh Availability"
                    : "Check Availability"
                    }}
                  </button>
                </div>
              </div>
            </div>
            <div id="truck_detail" class="px-3 py-4 py-md-3 py-lg-4" *ngIf="istruck_details">
          <div class="row">
            <div class="col-md-8 col-sm-12">
              <h3>Truck details</h3>
            </div>
            <div class="col-md-4 col-sm-12">
              <div class="measure_volume">
                <mat-button-toggle-group [disabled]="nodataavail" name="truck_number"
                aria-label="measure_volume">
                <mat-button-toggle checked value="one">1</mat-button-toggle>
                <mat-button-toggle value="two">2</mat-button-toggle>
                <mat-button-toggle value="three">3</mat-button-toggle>
              </mat-button-toggle-group>
              </div>
             
            </div>
            <div class="truck_table">
              <form action="">
                <table>
                  <thead>
                    <tr>
                      <th>Truck type</th>
                      <th>Load type</th>
                      <th>Carrier type</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <mat-form-field appearance="none">
                        <mat-select name="package" 
                          placeholder="TRAILER" >
                          <mat-select-trigger>
                            <mat-icon class="me-2"><img id="package_icon"  />
                            </mat-icon>
                      
                          </mat-select-trigger>
                          <mat-option
                           >
                            <mat-icon id="mat-icon"><img id="package_icon"/></mat-icon>
                        
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      </td>
                      <td>
                        <input type="text"/>
                      </td>
                      <td>
                        <input type="text"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table>
                  <thead>
                    <tr>
                      <th>Product Details</th>
                      <th>Value of Goods</th>
                      <th>Pickup time</th>
                      <th>Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input type="text"/>
                      </td>
                      <td>
                        <input type="text"/>
                      </td>
                      <td>
                        <input type="time"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
   <div class="row accessories_row">
    <div class="col">
      <p>Accessories</p>
    </div>
    <div class="col">
<input type="checkbox">
<p>belt</p>

    </div>
    <div class="col">
      <input type="checkbox">
<p>belt</p>
    </div>
    <div class="col"><input type="checkbox">
      <p>belt</p></div>
    <div class="col"><input type="checkbox">
      <p>belt</p></div>
    <div class="col"><input type="checkbox">
      <p>belt</p></div>
    <div class="col"><input type="checkbox">
      <p>belt</p></div>
   </div>

              </form>
            </div>
          </div>
            </div>
          </div>

          <!-- <div
              class="col-md-8 {{
                show_map ? 'd-flex align-items-center justify-content-center' : ''
              }} my-3 my-lg-0 p-lg-4"
              [hidden]="!show_map"
            >
              <div class="map" id="map" class="match-parent"></div>
            </div> -->
        </div>
      </div>

      <div class="panel container" id="panel" hide="!isActive">
        <div class="row px-lg-2 my-4">
          <div class="col-md-5 mt-3 mt-lg-0 mt-md-0 map_order">
            <div [hidden]="!show_map">
              <div class="map" id="map" class="match-parent"></div>
            </div>
          </div>
          <div class="col-md-7 ps-lg-0">
            <!-- <button
              mat-stroked-button
              class="book {{ item.booking_status ? 'close' : '' }}"
              [disabled]="!item.booking_status || !button_none"
              (click)="booking(item)"
            >
              Calculate Price
            </button> -->
            <div class="table_details" id="table_details" [hidden]="!show_map">
              <div class="progress-table pt-3 pb-1" id="progress-table">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Weight/Volume</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of availabilitylist">
                      <td>
                        {{ item.format_date }},
                        <span>{{ " " + item.day }}</span>
                      </td>
                      <td>
                        <div class="d-grid">
                          <div class="d-flex">
                            <div fxLayout="row" fxLayoutGap="1px" style="width: 100%; margin-bottom: 0.2rem"
                              class="progress_iteration">
                              <!-- border-radius: 0.9rem;
                            overflow: hidden; -->
                              <div *ngFor="
                                  let packs of item.weight_progress;
                                  index as w
                                " [hidden]="!packs.percent > 0" fxFlex="{{ packs.percent }}" class="progress_color {{
                                  item.weight_first == w ? 'border_first' : ''
                                }} {{
                                  item.weight_last == w ? 'border_last' : ''
                                }} {{
                                  !item.booking_status ? 'border_progress' : ''
                                }}
                                  {{
                                  item.weight_first == item.weight_last &&
                                  item.weight_first == w
                                    ? 'border_progress'
                                    : ''
                                }}"></div>
                            </div>
                            <p class="bar_unit ms-2 m-0">kg</p>
                          </div>
                          <div class="d-flex">
                            <div fxLayout="row" fxLayoutGap="1px" style="width: 100%" class="progress_iteration">
                              <!-- border-radius: 0.9rem;
                            overflow: hidden; -->
                              <div *ngFor="
                                  let packs of item.volume_progress;
                                  index as v
                                " [hidden]="!packs.percent > 0" fxFlex="{{ packs.percent }}" class="progress_color {{
                                  item.volume_first == v ? 'border_first' : ''
                                }} {{
                                  item.volume_last == v ? 'border_last' : ''
                                }}
                                  {{
                                  !item.booking_status ? 'border_progress' : ''
                                }}
                                  {{
                                  item.volume_first == item.volume_last &&
                                  item.volume_first == v
                                    ? 'border_progress'
                                    : ''
                                }}"></div>
                            </div>
                            <p class="bar_unit ms-2 m-0">m³</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <button mat-stroked-button class="book {{ item.booking_status ? 'close' : '' }}"
                          [disabled]="!item.booking_status || !button_none" (click)="booking(item)">
                          Calculate Price
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="colorcode px-4 py-4">
                <div class="row color_key">
                  <div class="col-md-8 info_text">
                    <p>Special requirements? Please call us. Happy to help.</p>
                    <mat-icon class="mr-2">phone_in_talk</mat-icon>
                  </div>
                  <div class="col-md-4">
                    <div class="d-flex colors">
                      <div class="filled d-flex align-items-center">
                        <div class="color_box"></div>
                        <span>Filled</span>
                      </div>
                      <div class="iteration d-flex align-items-center" *ngFor="let color of iteration">
                        <div class="color_box">
                          <div fxLayout="row" fxLayoutGap="1px" style="width: 100%" class="progress_iteration">
                            <div *ngFor="let packs of package; index as i" [hidden]="!packs.show"
                              fxFlex="{{ packs.percent }}" class="progress_color"></div>
                          </div>
                        </div>
                        <span>{{ color.color }}</span>
                      </div>
                      <div class="unfilled d-flex align-items-center">
                        <div class="color_box"></div>
                        <span>Unfilled</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 price_order">
            <div class="row pt-md-3 pt-lg-4" id="price_section" *ngIf="show_payment">
              <div class="col-md-12 col-lg-3 mt-3 mt-lg-0 col-12 Price_block text-center">
                <div>
                  <h4>Total Charges</h4>
                  <h3>₹ {{ price_obj.travel_charge }}</h3>
                  <p>
                    Please note that this amount is based on the inputs provided
                    during the booking process, it might be revised in case of
                    significant variations
                  </p>
                  <h4>Booking Advance</h4>
                  <h3>₹ {{ price_obj.advance }}</h3>
                  <p>
                    This is non-refundable, however can be adjusted in case of
                    date changes
                  </p>
                  <button mat-stroked-button (click)="paynow()">Pay Now</button>
                </div>
              </div>
              <div class="col-md-12 col-lg-9 col-12">
                <div class="blue_block {{ ispay ? '' : 'disabled' }}">
                  <div class="row mt-3 mt-lg-0 confirmation">
                    <div class="col-md-4 payment_method my-1">
                      <h4>Select payment method</h4>
                      <div class="selection d-flex align-items-center">
                        <div class="image_selection {{
                            isChecked ? '' : 'active'
                          }} w-100" id="qrcode">
                          <img src="/assets/images/qrcode.svg" alt="QR code" />
                        </div>
                        <div class="toggle_button w-100">
                          <mat-slide-toggle [(ngModel)]="isChecked" [disabled]="ispay ? false : true">
                          </mat-slide-toggle>
                        </div>
                        <div class="image_selection {{
                            isChecked ? 'active' : ''
                          }} w-100" id="bank">
                          <img src="/assets/images/bank.svg" alt="bank" />
                        </div>
                      </div>
                      <p *ngIf="!isChecked">
                        Please scan the QR code on the right
                      </p>
                      <p *ngIf="isChecked">
                        Please refer beneficiary details on the right
                      </p>
                    </div>
                    <div class="col-md-4 details_of_bank d-flex justify-content-center my-1">
                      <div class="d-flex align-items-center justify-content-center" *ngIf="!isChecked">
                        <div class="qrcode">
                          <img src="/assets/images/sampleqr.svg" alt="QR code" class="w-100" />
                        </div>
                      </div>
                      <div *ngIf="isChecked" class="bank_details">
                        <div class="inner_bank_div">
                          <h4>Bank Details</h4>
                          <table>
                            <tbody>
                              <tr>
                                <td>Account number</td>
                                <td>:</td>
                                <td>{{ account }}</td>
                              </tr>
                              <tr>
                                <td>Ifsc code</td>
                                <td>:</td>
                                <td>{{ ifsccode }}</td>
                              </tr>
                              <tr>
                                <td>Bank name</td>
                                <td>:</td>
                                <td>{{ bank_name }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 pay_cta my-1">
                      <button mat-stroked-button [disabled]="ispay ? false : true" (click)="confirm_pay()">
                        Update <br />Payment
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="what_we_do">
        <div class="container">
          <div class="row mx-lg-5 mt-lg-4 mt-4 mt-md-0 mx-md-4">
            <div class="col-md-6 my-md-3 mt-lg-0">
              <div class="pr-lg-2">
                <div class="title">
                  <h2>What We Do</h2>
                  <h4>Ship hassle-free with our platform.</h4>
                </div>
                <div class="text">
                  <p class="sub_text mt-lg-5 mt-4 mt-md-0">
                    At Chaavie, we believe there is always a better way of
                    moving goods. One where customers’ expectations are met
                    whilst keeping the value chain sustainable. We’re
                    obsessively optimistic about this concept, and our passion
                    has led us to develop a multi-modal sustainable platform
                    integrating technology into the traditional logistics
                    ecosystem.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-lg-2">
              <div class="row">
                <div class="col-md-6 p-md-1 px-lg-2 icon_wedolist col-lg-6" *ngFor="let item of wedolist">
                  <div class="icon d-flex align-items-end" [ngStyle]="{
                      margin: innerWidth <= 767 ? 'auto' : ''
                    }">
                    <img src="{{ item.img_url }}" class="object-fit" />
                  </div>
                  <div class="description mt-4 mt-md-2">
                    <h2>{{ item.title }}</h2>
                    <p>{{ item.text }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="solution">
        <div class="container pb-md-4 pb-4">
          <div class="row logistics mx-lg-2 px-lg-5 pb-lg-1 pt-lg-3">
            <div class="col-md-6 p-lg-5 p-4">
              <div class="image_section">
                <img src="/assets/images/delivery.svg" alt="solutions" />
              </div>
            </div>
            <div class="col-md-6 ps-lg-5 pe-lg-3 py-lg-5 p-4">
              <div class="title mt-4">
                <h2>Logistics Solutions</h2>
              </div>

              <div class="text">
                <p class="sub_text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Quisque quis egestas nunc. Sed in augue id tortor finibus
                  tincidunt. Fusce dignissim justo vitae ex interdum ultrices.
                  Sed faucibus eu lectus in bibendum. Nunc ullamcorper aliquam
                  turpis, eget placerat libero hendrerit nec.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="train">
            <img src="../assets/images/Train.svg" alt="" />
          </div> -->
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
<script src="/node_modules/configurable-date-input-polyfill/configurable-date-input-polyfill.dist.js"></script>