import { UserService } from './../service/user.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private UserService :UserService) {

  }

  ngOnInit(): void {

  }

  open_video(){
    this.UserService.onvideo_click()
  }
}
